import * as React from 'react';

import {
    ComboBox,
    DefaultButton,
    Dropdown,
    FontIcon,
    IComboBox,
    IComboBoxOption,
    IDropdownOption,
    IStackStyles,
    IconButton,
    MessageBar,
    MessageBarType,
    Modal,
    Persona,
    PersonaSize,
    Pivot,
    PivotItem,
    PrimaryButton,
    Spinner,
    Stack,
    Text,
    TextField,
    Toggle,
    loadTheme
} from '@fluentui/react';
import { NeutralColors, SharedColors } from '@fluentui/theme';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { contentStyles, dragOptions, iconButtonStyles } from '../Styles/Dialog/CommonControlEditor';
import { tagValidationErrorMessage, tagValidationRegex } from '../ControlEditor/constants';
import { useContext, useEffect, useState } from 'react';

import { AdminContext } from '../context/ControlEditor';
import { CollapsibleSection } from '@fluentui/react-experiments';
import { _WarningToast } from '../Page.styles';
import { authProvider } from '../../authProvider';
import { controlEditorTheme } from '../Themes/ControlEditorTheme';
import { panelClassNames } from './PersonaPanel';
import { toast } from 'react-toastify';
import { isJsonString } from '../Helper/JsonHelper';

const theme = loadTheme(controlEditorTheme);

class ControlChangeMapInterface {
    public Fields: boolean = false;
    public Description: boolean = false ;
    public ControlRequirements: boolean = false ;
    public ControlSeverity: boolean = false;
    public DisplayName: boolean = false ;
    public Category: boolean = false;
    public CustomTags: boolean = false;
    public AssessmentProperties: boolean = false;
    public ControlSettings: boolean = false;
    public CustomPolicyProperties: boolean = false;
    public CustomDeploymentPolicyProperties: boolean = false;
    public Enabled : boolean = false;
    public Rationale: boolean = false;
    public Recommendation: boolean = false;
    public ControlScanSource : boolean = false;
    public OriginalScanSource : boolean = false;
};

const cardStyle: IStackStyles = {
    root: {
      background: NeutralColors.gray30,
      boxShadow: theme.effects.elevation8,
      padding:20,
      borderLeft: '3px solid',
      borderColor: theme.palette.themePrimary
    },
  };

export const ControlEditorPanel: React.FunctionComponent = () => {
    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;
    const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;

    const {controlPanel, toggleControlPanel, selectedRow, setChangedData, changedData} = useContext(AdminContext);
    const [ControlChangeMap, setControlChangeMap] = useState<ControlChangeMapInterface>(new ControlChangeMapInterface());
    const [editable, setEditable] = useState<boolean>(false);
    const [editorStatus, setEditorStatus] = useState<boolean>(false);
    const [privileged, setPrivileged] = useState<boolean>(false);
    const [schemaLoaded, setSchemaLoaded] = useState<boolean>(false);
    const [schema, setSchema] = useState([]);
    const [schemaVersion, setSchemaVersion] = useState<string>("");
    const [ext, setExt] = useState<string[]>([]);
    const [desc, setDesc] = useState<string>('');
    const [customTags, setCustomTags] = useState<string[]>([]);
    const [displayName, setDisplayName] = useState<string>('');
    const [rationale, setRationale] = useState<string>('');
    const [recommendation, setRecommendation] = useState<string>('');
    const [controlRequirements, setControlRequirements] = useState<string>('');
    const [controlSeverity, setControlSeverity] = useState<string>('');
    const [category, setCategory] = useState<string>('');
    const [controlSettings, setControlSettings] = useState(null);
    const [assessmentProperties, setAssessmentProperties] = useState(null);
    const [customPolicyProperties, setCustomPolicyProperties] = useState(null);
    const [customDeploymentPolicyProperties, setCustomDeploymentPolicyProperties] = useState(null);
    const [controlScanSource, setControlScanSource] = useState<string>('');
    const [originalScanSource, setOriginalScanSource] = useState<string>('');

    const [enabled, setEnabled] = useState(null);
    const [foundExt, setFoundExt] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [changed, setChanged] = useState<boolean>(false);
    const [warningVisible, setWarningVisible] = useState(false);
    const [adv, setAdv] = useState<boolean>(false);
    const [tags, setTags] = useState<IDropdownOption[]>([]);
    const [customTagsOptions, setCustomTagsOptions] = useState<IComboBoxOption[]>([]);
    const [controlScanSourceOptions, setControlScanSourceOptions] = useState<IDropdownOption[]>([]);
    const [changeLog, setChangeLog] = useState<string[]>([]);
    const [panelMode, setPanelMode] = useState("settings");
    const [imageURL, setImageURL] = useState({});
    const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
    const [customTagsErrorMessage, setCustomTagsErrorMessage] = useState("");
    const [isPanelLoading, setIsPanelLoading] = useState<boolean>(false);
    const controlSeverityOptions : IDropdownOption[] = [
        {
            key: 'Low',
            text: 'Low',
        },
        {
            key: 'Medium',
            text: 'Medium',
        },
        {
            key: 'High',
            text: 'High',
        },
        {
            key: 'Critical',
            text: 'Critical',
        },
    ];

    const changeableScanSources = ["policyandreader", "mdcandreader", "mdcorreader"];

    function onClickSave(): void {
        var replicate = JSON.parse(JSON.stringify(changedData));
        var data = {};

        if(replicate[selectedRow["FeatureName"]] === undefined) {
            var control = {};
            if(ControlChangeMap.Fields === true){
                data["Fields"] = ext;
            }
            if(ControlChangeMap.Description === true){
                data["Description"] = desc;
            }
            if(ControlChangeMap.ControlRequirements === true){
                data["ControlRequirements"] = controlRequirements;
            }
            if(ControlChangeMap.ControlSeverity === true){
                data["ControlSeverity"] = controlSeverity;
            }
            if(ControlChangeMap.ControlScanSource === true){
                data["ControlScanSource"] = controlScanSource;
            }
            if(ControlChangeMap.OriginalScanSource === true){
                data["OriginalScanSource"] = originalScanSource;
            }
            if(ControlChangeMap.DisplayName === true){
                data["DisplayName"] = displayName;
            }
            if(ControlChangeMap.Category === true){
                data['Category'] = category;
            }
            if(ControlChangeMap.CustomTags === true){
                data['CustomTags'] = customTags;
            }
            if(ControlChangeMap.AssessmentProperties === true){
                data['AssessmentProperties'] = privileged ? assessmentProperties : null;
            }
            if(ControlChangeMap.ControlSettings === true){
                data['ControlSettings'] = privileged ? controlSettings : null;
            }
            if(ControlChangeMap.CustomPolicyProperties === true){
                data['CustomPolicyProperties'] = privileged ? customPolicyProperties : null;
            }
            if (ControlChangeMap.CustomDeploymentPolicyProperties === true) {
                data['CustomDeploymentPolicyProperties'] = privileged ? customDeploymentPolicyProperties : null;
            }
            if(ControlChangeMap.Enabled === true){
                data['Enabled'] = privileged ? enabled : null;
            }
            if(ControlChangeMap.Rationale === true){
                data['Rationale'] = rationale;
            }
            if(ControlChangeMap.Recommendation === true){
                data['Recommendation'] = recommendation;
            }
            data['SchemaVersion'] = schemaVersion;
            data['ControlId'] = selectedRow["ControlId"]
            control[selectedRow["Id"]] = data;
            replicate[selectedRow["FeatureName"]] = control;
        }
        else {
            if(replicate[selectedRow["FeatureName"]][selectedRow["Id"]] !== undefined){
                data = replicate[selectedRow["FeatureName"]][selectedRow["Id"]]
            }

            if(ControlChangeMap.Fields === true){
                data["Fields"] = ext;
            }
            if(ControlChangeMap.Description === true){
                data["Description"] = desc;
            }
            if(ControlChangeMap.ControlRequirements === true){
                data["ControlRequirements"] = controlRequirements;
            }
            if(ControlChangeMap.ControlSeverity === true){
                data["ControlSeverity"] = controlSeverity;
            }
            if(ControlChangeMap.ControlScanSource === true){
                data["ControlScanSource"] = controlScanSource;
            }
            if(ControlChangeMap.OriginalScanSource === true){
                data["OriginalScanSource"] = originalScanSource;
            }
            if(ControlChangeMap.DisplayName === true){
                data["DisplayName"] = displayName;
            }
            if(ControlChangeMap.Category === true){
                data['Category'] = category;
            }
            if(ControlChangeMap.CustomTags === true){
                data['CustomTags'] = customTags;
            }
            if(ControlChangeMap.AssessmentProperties === true){
                data['AssessmentProperties'] = privileged ? assessmentProperties : null;
            }
            if(ControlChangeMap.ControlSettings === true){
                data['ControlSettings'] = privileged ? controlSettings : null;
            }
            if(ControlChangeMap.CustomPolicyProperties === true){
                data['CustomPolicyProperties'] = privileged ? customPolicyProperties : null;
            }
            if (ControlChangeMap.CustomDeploymentPolicyProperties === true) {
                data['CustomDeploymentPolicyProperties'] = privileged ? customDeploymentPolicyProperties : null;
            }
            if(ControlChangeMap.Enabled === true){
                data['Enabled'] = privileged ? enabled.toString() : null;
            }
            if(ControlChangeMap.Rationale === true){
                data['Rationale'] = rationale;
            }
            if(ControlChangeMap.Recommendation === true){
                data['Recommendation'] = recommendation;
            }
            data['SchemaVersion'] = schemaVersion;
            data['ControlId'] = selectedRow["ControlId"]
            replicate[selectedRow["FeatureName"]][selectedRow["Id"]] = data;
        }
        setChangedData(replicate);
        setChanged(false);
    }

    const onRenderHeader = React.useCallback(
        () => (
            <Stack style={{marginBottom:10}}>
                <Stack style={{marginLeft:25,marginRight:25}} tokens={{childrenGap:-5}}>
                    <Stack horizontal tokens={{childrenGap:15}} horizontalAlign='space-between' >
                        <Stack horizontal tokens={{childrenGap:0}}>
                            <Text variant='xxLarge'>
                                {selectedRow.FeatureName}
                            </Text>
                            {
                                changed === true &&
                                <FontIcon iconName="AsteriskSolid" style={{color:SharedColors.redOrange10,fontSize:12}} />
                            }
                        </Stack>
                        {
                            editorStatus && 
                            <Toggle
                                label="Edit"
                                inlineLabel
                                defaultChecked={editable ? true : false}
                                onChange={
                                    ()=>{
                                        setEditable(!editable);
                                    }
                                }
                            />
                        }
                        
                    </Stack>
                    <Text variant='xLarge' style={{color:theme.palette.themeDarkAlt}} nowrap>
                        {selectedRow.ControlId}
                    </Text>
                </Stack>
                <Stack style={{marginLeft:20}}>
                    <Pivot
                        aria-label="Basic Pivot Example"
                        onLinkClick={handleLinkClick}
                        defaultSelectedKey={panelMode}
                    >
                        
                        <PivotItem
                            headerText="Settings"
                            itemIcon="Settings"
                            itemKey="settings"
                        >
                        </PivotItem>
                        {
                            privileged && 
                            <PivotItem
                                headerText="Advanced"
                                itemIcon="PlayerSettings"
                                itemKey="privileged"
                            >
                            </PivotItem>
                            
                        }                        
                        <PivotItem
                            headerText="Change Log"
                            itemIcon="ContextMenu"
                            itemKey="changelog"
                        >
                        </PivotItem>
                    </Pivot>
                </Stack>
            </Stack>
        ),
        // eslint-disable-next-line
        [selectedRow, editable, changed, privileged, editorStatus],
    );

    const onRenderFooterContent = React.useCallback(
        () => (
          <Stack horizontal reversed horizontalAlign='space-between'>
            
            <Stack horizontal tokens={{childrenGap:15}}>
                {editorStatus && 
                    <PrimaryButton onClick={onClickSave} disabled={!changed || !loaded || 
                        (assessmentProperties && !isJsonString(assessmentProperties)) || 
                        (controlSettings && !isJsonString(controlSettings))} iconProps={{iconName:'BuildQueueNew'}}>
                        Queue for Update
                    </PrimaryButton>
                }                     
                <DefaultButton 
                    iconProps={{iconName:'ChromeClose'}}
                    onClick={toggleControlPanel}>
                    Close
                </DefaultButton>
            </Stack>
            
            
            <Stack>
                    <Spinner label="Checking for extension files" ariaLive="assertive" labelPosition="right" 
                        style={{visibility: loaded? 'hidden': 'visible'}}
                    />
            </Stack>
          </Stack>
        ),
        // eslint-disable-next-line
        [editorStatus, ext, loaded, changed, desc, controlRequirements, controlSeverity, controlScanSource, category, displayName, customTags, assessmentProperties, controlSettings, enabled, recommendation, rationale, ControlChangeMap]
    );

    useEffect(() => {
        if(panelMode === 'changelog') {
            const getAuthToken = async () => {
                let headers = new Headers();
                let token = (await authProvider.getAccessToken()).accessToken;
                headers.append('Authorization', token.toString());
                headers.append('x-content-type-options', 'nosniff');
                headers.append('content-type', 'application/json');
                return headers;
            };
            getAuthToken().then((headers) => {
                var request_batch = [];
                var body = {};
                var increment = 1;
                var userDict = {};
                var reverseUserDict = {};
                changeLog.forEach(element => {
                    if(imageURL[element['user']] === undefined && reverseUserDict[element['user']] === undefined) {
                        var individual_request = {};
                        individual_request['id'] = increment.toString();
                        individual_request['method'] = 'GET';
                        individual_request['url'] = '/users/'+ element['user'] + '/photo/$value';
                        request_batch = request_batch.concat(individual_request);
                        userDict[increment] = element['user'];
                        reverseUserDict[element['user']] = increment;
                        increment += 1;
                    }
                });
                body['requests'] = request_batch;

                // TODO: Move all HTTP calls to a helper.
                if(request_batch.length > 0) {
                    fetch(`${RuntimeConfigurationExtended.graphAPI}/v1.0/$batch`, {
                        headers: headers,
                        method: 'POST',
                        body: JSON.stringify(body)
                    })
                    .then(response => response.json())
                    .then(data => {
                        var copyImageUrl = JSON.parse(JSON.stringify(imageURL));
                        data["responses"].forEach(element => {
                            if(element['status'] === 200) {
                                const byteCharacters = atob(element['body']);
                                const byteNumbers = new Array(byteCharacters.length);
                                for (let i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                }
                                const byteArray = new Uint8Array(byteNumbers);
                                let image = new Blob([byteArray], { type: 'image/jpeg' });
                                let imageUrl = URL.createObjectURL(image);
                                copyImageUrl[userDict[element['id']]] = imageUrl;
                            }
                            else {
                                // There are cases where a user may not have a display picture.
                                // const errorMessage = "ERROR!!! Status: " + element['status'] +
                                //     " Code: " + element['body']['error']['code'] +
                                //     " Message: " + element['body']['error']['message'];
                            }
                        });
                        setImageURL(copyImageUrl);
                    });
                }
            })
        }
        if(panelMode === "privileged"){
            _getPrivilegedData();
        }
    },
    // eslint-disable-next-line
    [panelMode]);


    const FetchSchema = async () => {
        const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
        let body = {
            ResourceType: selectedRow["FeatureName"],
            Control: selectedRow["ControlId"],
            Schema: true
        }
        fetch((ScannerAPI + '/controleditor/fetchschema/'), {
            headers: !authToken.accessToken ? {} : {
                'Authorization': `Bearer ${authToken.accessToken}`,
                'u_Id': sessionStorage.getItem("u_Id"),
                'Content-Type': 'application/json'},
            method: 'POST',
            body: JSON.stringify(body)
        }).then(response => {
            setSchemaLoaded(true);
            if (response.ok) {
                response.json().then(data => {
                    setSchema(data["fields"]);
                    setSchemaVersion(data["version"]);
                });
            }
        });
    }
        
    useEffect(() => {
        _checkPrivilegedAccess();
        _checkEditorAccess();
    },
    // eslint-disable-next-line
    []);

    function _checkPrivilegedAccess() {
        const CallAPI = async () => {
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            fetch((ScannerAPI + '/controleditor/privilegedcheckstatus'), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'
                },
                method: 'POST',
            }).then(response => {
                if (response.ok) {
                    setPrivileged(true);
                }
                else{
                    setPrivileged(false);
                }
            })
        }
        CallAPI();
    }

    // TODO: Shift _checkEditorAccess and _checkPrivilegedAccess to common helper.
    // TODO: Common API call for both.
    function _checkEditorAccess() {
        const CallAPI = async () => {
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            fetch((ScannerAPI + '/controleditor/editorcheckstatus'), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'
                },
                method: 'POST',
            }).then(response => {
                if (response.ok) {
                    setEditorStatus(true);
                }
                else{
                    setEditorStatus(false);
                }
            })
        }
        CallAPI();
    }


    async function _getPrivilegedData () {
        const CallAPI = async () => {
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            let body = {
                ResourceType: selectedRow["FeatureName"],
                Id: selectedRow["Id"],
                Schema: false
            }
            fetch((ScannerAPI + '/controleditor/privilegedfetch'), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(body)
            }).then(response => {
                if (response.ok) {
                    response.json().then(data => { 
                        setEnabled(data["enabled"]==="true");
                        setAssessmentProperties(JSON.stringify(data["assessmentProperties"]));
                        setControlSettings(JSON.stringify(data["controlSettings"]));
                        setCustomPolicyProperties(JSON.stringify(data["customPolicyProperties"]));
                        setCustomDeploymentPolicyProperties(JSON.stringify(data["customDeploymentPolicyProperties"]));
                    })
                }
            })
        }

        setIsPanelLoading(true);
        try{
            await CallAPI();
        }
        finally{
            setIsPanelLoading(false);
        }
    }

    useEffect(() => {
        if(controlScanSource && changeableScanSources.includes(controlScanSource.toLowerCase())){
            setControlScanSourceOptions([{key:"Reader",text:"Reader"},{key:controlScanSource,text:controlScanSource}]);
        }
        else if( originalScanSource != null){
            setControlScanSourceOptions([{key:originalScanSource,text:originalScanSource}, {key:controlScanSource,text:controlScanSource}]);
        }
        else{
            setControlScanSourceOptions([{key:controlScanSource,text:controlScanSource}]);
        }
    }, [controlScanSource])


    useEffect(() => {
        if(controlPanel === true){
            setDesc(selectedRow["Description"]);
            setDisplayName(selectedRow["DisplayName"]);
            setControlRequirements(selectedRow['ControlRequirements']);
            setControlSeverity(selectedRow['ControlSeverity']);
            setControlScanSource(selectedRow['ControlScanSource']);
            setControlScanSourceOptions([{key:selectedRow['ControlScanSource'],text:selectedRow['ControlScanSource']}]);
            setOriginalScanSource(selectedRow['OriginalScanSource']);
            setCustomTags(selectedRow['CustomTags']);
            setCategory(selectedRow['Category']);
            setRationale(selectedRow['Rationale']);
            setRecommendation(selectedRow['Recommendation']);
            const CallAPI = async () => {
                setLoaded(false);
                const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
                let body = {
                    ResourceType: selectedRow["FeatureName"],
                    Id: selectedRow["Id"],
                    Schema: false
                }
                fetch((ScannerAPI + '/controleditor/fetchextension/'), {
                    headers: !authToken.accessToken ? {} : {
                        'Authorization': `Bearer ${authToken.accessToken}`,
                        'u_Id': sessionStorage.getItem("u_Id"),
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify(body)
                }).then(response => {
                    if (response.ok) {
                        response.json().then(data => {
                            var emptyArray = [];
                            for (let index = 0; index < schema.length; index++) {
                                if(schema[index].type === 'dropdown-single') {
                                    emptyArray = emptyArray.concat(['']);
                                }
                                else {
                                    emptyArray = emptyArray.concat('');
                                }
                            }
                            if(data["control"] === null && data["changeLog"] === null) {
                                setLoaded(true);
                                setExt(emptyArray);
                                setFoundExt(false);
                                setChanged(false);
                                setWarningVisible(true);
                            }
                            else if(data["control"] === null) {
                                setExt(emptyArray);
                                setFoundExt(false);
                                setLoaded(true);
                                setChanged(false);
                                setWarningVisible(true);
                                setChangeLog(data["changeLog"].reverse());
                            }
                            else {
                                if(data["control"]["fields"]===null) setExt(emptyArray);
                                else setExt(data["control"]["fields"]);
                                if(data["control"]["description"]) {
                                    setDesc(data["control"]["description"]);
                                }

                                if(data["control"]["customTags"] != null && data["control"]["customTags"]?.length > 0){
                                    var customTagsOptions : IComboBoxOption[] = [];
                                    var customTags = [];
                                    for (let j = 0; j < data["control"]["customTags"]?.length; j++) {
                                        customTagsOptions = customTagsOptions.concat({
                                            key: data["control"]["customTags"][j],
                                            text: data["control"]["customTags"][j],
                                        });
                                        customTags = customTags.concat(data["control"]["customTags"][j])
                                    }
                                    setCustomTagsOptions(customTagsOptions);
                                    setCustomTags(customTags);
                                }
                                
                                if(data["control"]["displayName"]) {
                                    setDisplayName(data["control"]["displayName"]);
                                }
                                if(data["control"]["controlRequirements"]) {
                                    setControlRequirements(data["control"]["controlRequirements"]);
                                }
                                if(data["control"]["controlSeverity"]){
                                    setControlSeverity(data["control"]["controlSeverity"]);
                                }
                                if(data["control"]["controlScanSource"]){
                                    setControlScanSource(data["control"]["controlScanSource"]);
                                }
                                if(data["control"]["originalScanSource"]){
                                    setOriginalScanSource(data["control"]["originalScanSource"]);
                                } 
                                if(data["control"]["category"]) {
                                    setCategory(data["control"]["category"]);
                                }
                                if(data["control"]["rationale"]) {
                                    setRationale(data["control"]["rationale"]);
                                }
                                if(data["control"]["recommendation"]) {
                                    setRecommendation(data["control"]["recommendation"]);
                                }
                                setFoundExt(true);
                                setLoaded(true);
                                setChanged(false);
                                if(data["changeLog"]) {
                                    setChangeLog(data["changeLog"].reverse());
                                }
                            }
                        });
                    }
                    else {
                        response.text().then(errorMsg => {
                            toast(errorMsg.slice(0, 500));
                        });
                    }
                });
            }

            FetchSchema();
            try {
                var tagsOptions : IDropdownOption[] = [];
                for (let j = 0; j < selectedRow?.Tags?.length; j++) {
                    tagsOptions = tagsOptions.concat({
                        key: selectedRow.Tags[j],
                        text: selectedRow.Tags[j],
                        disabled: true,
                    });
                }
                setTags(tagsOptions);
            } catch (error) {}

            try{
                var customTagsOptions : IComboBoxOption[] = [];
                var customTags = [];
                for (let j = 0; j < selectedRow?.CustomTags?.length; j++) {
                    customTagsOptions = customTagsOptions.concat({
                        key: selectedRow.CustomTags[j],
                        text: selectedRow.CustomTags[j],
                    });
                    customTags = customTags.concat(selectedRow.CustomTags[j])
                }
                setCustomTagsOptions(customTagsOptions);
                setCustomTags(customTags);

            }
            catch(error){}

            try {
                var data = changedData[selectedRow.FeatureName][selectedRow.Id];
                if(data["Description"] != null){
                    setDesc(data["Description"]);
                }
                if(data["DisplayName"] != null){
                    setDisplayName(data["DisplayName"]);
                }
                if(data['ControlRequirements'] != null){
                    setControlRequirements(data['ControlRequirements']);
                }
                if(data['ControlSeverity'] != null){
                    setControlSeverity(data['ControlSeverity']);
                }
                if(data['ControlScanSource'] != null){
                    setControlScanSource(data['ControlScanSource']);
                }
                if(data['OriginalScanSource'] != null){
                    setOriginalScanSource(data['OriginalScanSource']);
                }
                if(data['CustomTags'] != null){
                    setCustomTags(data['CustomTags']);
                }
                if(data['Category'] != null){
                    setCategory(data['Category']);
                }
                if(data["fields"] != null){
                    setExt(data["fields"]);
                }
                if(data["Rationale"] != null){
                    setRationale(data["Rationale"]);
                }
                if(data["Recommendation"] != null){
                    setRecommendation(data["Recommendation"]);
                }
                if(privileged){
                    if(data["Enabled"] != null){
                        setEnabled(data["Enabled"]);
                    }
                    if(data["ControlSettings"] != null){
                        setControlRequirements(data["ControlSettings"]);
                    }
                    if(data["AssessmentProperties"] != null){
                        setAssessmentProperties(data["AssessmentProperties"]);
                    }
                    if(data["CustomPolicyProperties"] != null){
                        setCustomPolicyProperties(data["CustomPolicyProperties"]);
                    }
                    if (data["CustomDeploymentPolicyProperties"] != null) {
                        setCustomDeploymentPolicyProperties(data["CustomDeploymentPolicyProperties"]);
                    }
                }                
                setChanged(false);
            } catch {
                CallAPI();
            }
        }
    },
    // eslint-disable-next-line
    [controlPanel]);

    const handleLinkClick = (item: PivotItem) => {
        setPanelMode(item.props.itemKey!);
    };

    const onChangeText = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(editable && newValue.length <= RuntimeConfigurationExtended.controlEditorFeatureConfiguration.shortWordLimit.valueOf()) {
            var htmlElement = event.target as HTMLDivElement;
            var index = htmlElement.getAttribute('data-index');
            var currentFields = JSON.parse(JSON.stringify(ext));
            currentFields[index] = newValue;
            setExt(currentFields);
            setChanged(true);
            setControlChangeMap({...ControlChangeMap,Fields:true});
        }
    };

    const onChangeDesc = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(editable && newValue.length <= RuntimeConfigurationExtended.controlEditorFeatureConfiguration.mediumWordLimit.valueOf()) {
            setDesc(newValue);
            setChanged(true);
            setControlChangeMap({...ControlChangeMap,Description:true});
        }
    };

    const onChangeDisplayName = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(editable && newValue.length <= RuntimeConfigurationExtended.controlEditorFeatureConfiguration.shortWordLimit.valueOf()) {
            setDisplayName(newValue);
            setChanged(true);
            setControlChangeMap({...ControlChangeMap,DisplayName:true});

        }
    };

    const onChangeCustomTags = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        let selected = option?.selected;
        setCustomTagsErrorMessage("");
        if (!option && value) {
            if(!tagValidationRegex.test(value)) {
                setCustomTagsErrorMessage(tagValidationErrorMessage);
                return;
            }
        }
        if (!option && value) {
            selected = true;
            option = { key: value, text: value };
            setCustomTagsOptions(customTagsOptions => [...customTagsOptions, option!]);
        }

        if (option) {
            setCustomTags(prevSelectedKeys =>
                selected ? [...prevSelectedKeys, option!.text as string] : prevSelectedKeys.filter(k => k !== option!.text),
            );
        }
        setChanged(true);     
        setControlChangeMap({...ControlChangeMap,CustomTags:true});
             
    }

    const onChangeCategory = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(editable && newValue.length <= RuntimeConfigurationExtended.controlEditorFeatureConfiguration.shortWordLimit.valueOf()) {
            setCategory(newValue);
            setChanged(true);
            setControlChangeMap({...ControlChangeMap,Category:true});
        }
    };

    const onChangeControlRequirements = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(editable && newValue.length <= RuntimeConfigurationExtended.controlEditorFeatureConfiguration.shortWordLimit.valueOf()) {
            setControlRequirements(newValue);
            setChanged(true);
            setControlChangeMap({...ControlChangeMap,ControlRequirements:true});

        }
    };

    const onChangeControlSeverity = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption, index?: number) => {
        if(editable){
            setControlSeverity(option.text);
            setChanged(true);
            setControlChangeMap({...ControlChangeMap,ControlSeverity:true});
        }
    };

    const onChangeControlScanSource = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption, index?: number) => {
        if(editable){
            if(originalScanSource === null || originalScanSource === undefined){
                setOriginalScanSource(controlScanSource);
                setControlChangeMap(prevValue => ({...prevValue, OriginalScanSource:true}));
            }
            setControlScanSource(option.text);
            setControlChangeMap(prevValue => ({...prevValue, ControlScanSource:true}));
            setChanged(true);
        }
    }

    const onChangeControlSettings = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(editable && newValue.length <= RuntimeConfigurationExtended.controlEditorFeatureConfiguration.longWordLimit.valueOf()) {
            setControlSettings(newValue);
            setChanged(true);
            setControlChangeMap({...ControlChangeMap,ControlSettings:true});

        }
    };

    const onChangeAssessmentProperties = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(editable && newValue.length <= RuntimeConfigurationExtended.controlEditorFeatureConfiguration.mediumWordLimit.valueOf()) {
            setAssessmentProperties(newValue);
            setChanged(true);
            setControlChangeMap({...ControlChangeMap,AssessmentProperties:true});
        }
    };

    const onChangeCustomPolicyProperties = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(editable && newValue.length <= RuntimeConfigurationExtended.controlEditorFeatureConfiguration.shortWordLimit.valueOf()) {
            setCustomPolicyProperties(newValue);
            setChanged(true);
            setControlChangeMap({...ControlChangeMap,CustomPolicyProperties:true});
        }
    };

    const onChangeCustomPolicyDeploymentProperties = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (editable && newValue.length <= RuntimeConfigurationExtended.controlEditorFeatureConfiguration.mediumWordLimit.valueOf()) {
            setCustomDeploymentPolicyProperties(newValue);
            setChanged(true);
            setControlChangeMap({ ...ControlChangeMap, CustomDeploymentPolicyProperties: true });
        }
    };

    const onChangeEnabled = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        if(editable) {
            setEnabled(checked);
            setChanged(true);
            setControlChangeMap({...ControlChangeMap,Enabled:true});

        }
    };

    const onChangeRationale = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(editable && newValue.length <= RuntimeConfigurationExtended.controlEditorFeatureConfiguration.shortWordLimit.valueOf()) {
            setRationale(newValue);
            setChanged(true);
        }
    };

    const onChangeRecommendation = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(editable && newValue.length <= RuntimeConfigurationExtended.controlEditorFeatureConfiguration.shortWordLimit.valueOf()) {
            setRecommendation(newValue);
            setChanged(true);
        }
    };

    const advToggled = (ev: React.MouseEvent<Element>) => {
        setAdv(!adv);
    }

    const onDismiss = (ev?: React.SyntheticEvent | KeyboardEvent) => {
        if(changed === true) {
            setIsDialogVisible(true);
        }
        else {
            hideDialogAndPanel();
        }
    }

    const hideDialog = React.useCallback(() => setIsDialogVisible(false), []);

    function _purgeInfoOnClose() {
        setDisplayName(null);
        setControlRequirements(null);
        setControlSeverity(null);
        setControlScanSource(null);
        setOriginalScanSource(null);
        setDesc(null);
        setCustomTags([]);
        setTags([]);
        setExt(null);
        setCategory(null);
        setRecommendation(null);
        setRationale(null);
        setControlSettings(null);
        setAssessmentProperties(null);
        setCustomPolicyProperties(null);
        setCustomDeploymentPolicyProperties(null);
        setEnabled(null);
    }

    const hideDialogAndPanel = React.useCallback(() => {
        setChangeLog([]);
        _purgeInfoOnClose();
        setPanelMode("settings");
        toggleControlPanel();
        setAdv(false);
        setEditable(false);
        setChanged(false);
        setIsDialogVisible(false);
        setControlChangeMap(new ControlChangeMapInterface());
    },
    // eslint-disable-next-line
    []);

    return (
        <div>
            <Modal
                isOpen={isDialogVisible}
                containerClassName={contentStyles.container3}
                onDismiss={hideDialog}
                dragOptions={dragOptions}
            >
                <div className={contentStyles.header}>
                <span >Quit Editing?</span>
                <IconButton
                    style={{outline:"none"}}
                    styles={iconButtonStyles}
                    iconProps={{iconName:'Cancel'}}
                    ariaLabel="Close popup modal"
                    onClick={hideDialog}
                />
                </div>
                <div className={contentStyles.body}>
                    <div>
                        The changes you made will be lost. Are you sure you want to quit?
                    </div>
                    <Stack {...{horizontal: true, verticalAlign: 'center'}} horizontalAlign={"space-between"}
                        reversed style={{padding: 0, margin: 0}} tokens={{childrenGap: 10}}>
                        <Stack horizontal reversed tokens={{childrenGap: 10}} style={{marginTop: 20}}>
                            <DefaultButton onClick={hideDialog} text="No" />
                            <PrimaryButton onClick={hideDialogAndPanel} text="Yes" />
                        </Stack>
                    </Stack>
                </div>
            </Modal>

            <Panel
                isOpen={controlPanel}
                isLightDismiss
                type={PanelType.custom}
                headerClassName={panelClassNames.headerClass}
                customWidth={'45%'}
                onRenderFooterContent={onRenderFooterContent}
                onRenderHeader={onRenderHeader}
                onDismiss={onDismiss}
                hasCloseButton={false}
                headerText="Control Setting"
                isFooterAtBottom={true}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close"
            >
            {
                panelMode === "settings" &&
                <Stack tokens={{childrenGap:10}}>
                    {!foundExt && loaded && warningVisible &&
                        <Stack style={{marginTop:5}}>
                            <MessageBar
                                messageBarType={MessageBarType.warning}
                                isMultiline={false}
                                dismissButtonAriaLabel="Close"
                                onDismiss={
                                    ()=>{
                                        setWarningVisible(false);
                                    }
                                }
                            >
                                The extension file for this control was not found.
                            </MessageBar>
                        </Stack>
                    }

                    <TextField
                        label="Display Name"
                        value={displayName}
                        disabled={!editable}
                        onChange={onChangeDisplayName}
                    />
                    <Dropdown
                        label={"Control Scan Source"}
                        defaultSelectedKey={controlScanSource}
                        options={controlScanSourceOptions}
                        disabled={!editable}
                        onChange={onChangeControlScanSource}
                    />
                    <TextField
                        label="Category"
                        value={category}
                        disabled={!editable}
                        onChange={onChangeCategory}
                    />
                    <TextField
                        label="Description"
                        multiline
                        value={desc}
                        disabled={!editable}
                        autoAdjustHeight
                        onChange={onChangeDesc}
                    />
                    <TextField
                        label="Control Requirement"
                        multiline
                        value={controlRequirements}
                        disabled={!editable}
                        onChange={onChangeControlRequirements}
                    />
                    <Dropdown
                        label={"Control Severity"}
                        defaultSelectedKey={controlSeverity}
                        options={controlSeverityOptions}
                        disabled={!editable}
                        onChange={onChangeControlSeverity}
                    />
                    <Dropdown
                        label={"Tags"}
                        multiSelect
                        selectedKeys={selectedRow.Tags}
                        options={tags}
                        disabled={!editable}
                    />
                    <ComboBox
                        label={"Custom Tags"}
                        selectedKey={customTags}
                        options={customTagsOptions}
                        disabled={!editable}
                        multiSelect
                        autoComplete="on"
                        allowFreeform={true}
                        onChange={onChangeCustomTags}
                        errorMessage={customTagsErrorMessage}
                        onMouseLeave={() => setCustomTagsErrorMessage("")}
                    />
                    <TextField
                        label="Rationale"
                        multiline
                        value={rationale}
                        disabled={!editable}
                        onChange={onChangeRationale}
                    />
                    <TextField
                        label="Recommendation"
                        multiline
                        value={recommendation}
                        disabled={!editable}
                        autoAdjustHeight
                        onChange={onChangeRecommendation}
                    />
                    {
                        schemaLoaded === true && schema.map((field, index) => {
                            if(loaded === true && field.advanced === false) {
                                switch(field.type) {                                    
                                    case "long-text":
                                        return (
                                            <TextField
                                                label={field.label + " (Max 1000 characters)"}
                                                value={ext[index]}
                                                disabled={!editable}
                                                multiline
                                                readOnly={!editable}
                                                errorMessage={ext[index] ? ext[index].length <= RuntimeConfigurationExtended.controlEditorFeatureConfiguration.shortWordLimit.valueOf() ? "" : "Character limit reached" : ""}
                                                autoAdjustHeight
                                                resizable={false}
                                                data-index={index}
                                                onChange={onChangeText}
                                            />
                                        );
                                    case "text":
                                        return (
                                            <TextField
                                                label={field.label + " (Max 1000 characters)"}
                                                value={ext[index]}
                                                disabled={!editable}
                                                data-index={index}
                                                readOnly={!editable}
                                                errorMessage={ext[index] ? ext[index].length <= RuntimeConfigurationExtended.controlEditorFeatureConfiguration.shortWordLimit.valueOf() ? "" : "Character limit reached" : ""}
                                                onChange={onChangeText}
                                            />
                                        );
                                }
                            }
                            return true;
                        })
                    }

                    {schema.length > 0 && schemaLoaded &&
                        <CollapsibleSection
                            key={1}
                            defaultCollapsed={true}
                            collapsed={!adv}
                            title={{
                                text: "Additional Settings",
                                "aria-label":'Collapse subscription',
                                chevron: {color: theme.palette.themePrimary, style:{outline:"none"}},
                                styles: {
                                    text:theme.fonts.xLarge,
                                    root: {
                                        marginTop: 10,
                                        color: theme.palette.themeDarkAlt,
                                        outline: "none !important",
                                        selectors: {
                                            ':hover': {
                                                backgroundColor: 'inherit'
                                            }
                                        }
                                    }
                                },
                                onClick: advToggled
                            }}
                        >
                        </CollapsibleSection>
                    }
                    {
                    
                        schemaLoaded===true && adv===true && ext!=null && schema.map((field, index) => {
                            if(loaded === true && field.advanced === true) {
                                switch(field.type) {
                                    case "long-text":
                                        return (
                                            <TextField
                                                key={field.label}
                                                label={field.label + " (Max 1000 characters)"}
                                                value={ext[index]}
                                                disabled={!editable}
                                                multiline
                                                autoAdjustHeight
                                                readOnly={!editable}
                                                errorMessage={ext[index] ? ext[index].length <= RuntimeConfigurationExtended.controlEditorFeatureConfiguration.shortWordLimit.valueOf() ? "" : "Character limit reached" : ""}
                                                data-index={index}
                                                onChange={onChangeText}
                                            />
                                        );
                                    case "text":
                                        return (
                                            <TextField
                                                key={field.label}
                                                label={field.label + " (Max 1000 characters)"}
                                                value={ext[index]}
                                                disabled={!editable}
                                                data-index={index}
                                                readOnly={!editable}
                                                errorMessage={ext[index] ? ext[index].length <= RuntimeConfigurationExtended.controlEditorFeatureConfiguration.shortWordLimit.valueOf() ? "" : "Character limit reached" : ""}
                                                onChange={onChangeText}
                                            />
                                        );
                                }
                            }
                            return true;
                        })
                    }
                </Stack>
            }
            { panelMode === "privileged" && (isPanelLoading || enabled == null)  && <Spinner label="Loading..." ariaLive="assertive" labelPosition="right" />}
            {
                panelMode === "privileged" && !isPanelLoading && enabled != null &&
                <Stack tokens={{childrenGap:10}}>
                    <TextField
                        label="Control Settings"
                        multiline
                        value={controlSettings}
                        disabled={!editable}
                        autoAdjustHeight
                        onChange={onChangeControlSettings}
                        errorMessage={(controlSettings && isJsonString(controlSettings)) ? "" : "Invalid JSON"}
                    />
                    <TextField
                        label="Assessment Properties"
                        multiline
                        value={assessmentProperties}
                        disabled={!editable}
                        autoAdjustHeight
                        onChange={onChangeAssessmentProperties}
                        errorMessage={(assessmentProperties && isJsonString(assessmentProperties)) ? "" : "Invalid JSON"}
                    />
                    <TextField
                        label="Custom Policy Properties"
                        multiline
                        value={customPolicyProperties}
                        disabled={!editable}
                        autoAdjustHeight
                        onChange={onChangeCustomPolicyProperties}
                        errorMessage={(customPolicyProperties && isJsonString(customPolicyProperties)) ? "" : "Invalid JSON"}
                    />
                    <TextField
                        label="Custom Policy Deployment Properties"
                        multiline
                        value={customDeploymentPolicyProperties}
                        disabled={!editable}
                        autoAdjustHeight
                        onChange={onChangeCustomPolicyDeploymentProperties}
                        errorMessage={(customDeploymentPolicyProperties && isJsonString(customDeploymentPolicyProperties)) ? "" : "Invalid JSON"}
                    />
                    <Toggle
                        label="Enabled" 
                        checked={enabled} 
                        disabled={!editable}
                        onText="Enabled" 
                        offText="Disabled" 
                        onChange={onChangeEnabled}
                    />

                </Stack>
            }
            {
                panelMode === "changelog" &&
                <Stack>
                    <Text variant="large">
                        Change logs for the {selectedRow["FeatureName"]}
                    </Text>
                    {
                        changeLog.length === 0 &&
                        <Text>No logs available.</Text>
                    }
                    {
                        changeLog.length > 0 &&
                        changeLog.map((log, index) => {
                            return (
                                // key is required for the children to have an unique identifier.
                                <Stack key={index} styles={cardStyle} style={{marginTop:20}} tokens={{childrenGap:10}}>
                                    <Persona
                                        imageUrl={imageURL[log["user"]]}
                                        size={PersonaSize.size48}
                                        imageShouldFadeIn={true}
                                        text={log["user"]}
                                        secondaryText={"Edited on: " + log["date"]}
                                    />
                                    <Text><b style={{fontWeight:600}}>Comments:</b>{" "+log["comment"]}</Text>
                                </Stack>
                            );
                        })
                    }
                </Stack>
            }
            </Panel>
        </div>
    );
};
