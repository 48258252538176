import React, { useContext, useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { DefaultButton, DetailsList, IColumn, IconButton, loadTheme, MessageBar, MessageBarType, Modal,
    PrimaryButton, SelectionMode, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { _Styles } from '../Page.styles';
import { AdminContext } from '../context/ControlEditor';
import { toast } from 'react-toastify';
import { authProvider } from '../../authProvider';
import { contentStyles, dragOptions, iconButtonStyles } from '../Styles/Dialog/CommonControlEditor';
import { ChangeLog } from '../Interface/IAdminTable';
import { controlEditorTheme } from '../Themes/ControlEditorTheme';

const columns: IColumn[] = [
    {
        key: 'FeatureName',
        name: 'Feature Name',
        fieldName: 'FeatureName',
        minWidth: 20,
        isPadded: false,
        maxWidth: 200,
        isResizable: true
    }, {
        key: 'ControlID',
        name: 'ControlID',
        fieldName: 'ControlID',
        minWidth: 20,
        isPadded: false,
        maxWidth: 200,
        isResizable: true
    }
];

export const UpdateButton: React.FunctionComponent<{}> = () => {
    loadTheme(controlEditorTheme);
    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
    const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;

    const {changedData,setChangedData,modelOpenUpdate,toggleModelOpenUpdate} = useContext(AdminContext);
    const [displayTable, setDisplayTable] = useState([]);
    const [spinner, setSpinner] = React.useState<boolean>(false);
    const [showMessage, setShowMessage] = React.useState<boolean>(false);
    const [showMessageError, setShowMessageError] = React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const [comments, setComments] = useState('');

    const SuccessExample = () => (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={true}
        >  
            <div>
                The control metadata has been successfully updated in the system.
            </div>
            <div>
                Future scan results will show the updated control metadata.
            </div>
        </MessageBar>
    );

    const VersionMismatchExample = () => (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
        >  
            <div>
                The schema has been updated. Your current request cannot be processed.
            </div>
            <div>
                Please refresh the page and make your changes again.
            </div>
        </MessageBar>
    );


    function submitData(): void {
        const CallAPI = async () => {
            setSpinner(true);
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            var features = [];
            var privilegedRequest = false;
            var schemaVersion = ""
            var schemaConsistent: boolean = true;
            Object.entries(changedData).forEach(([key, value]) => {
                var controls = [];
                Object.entries(value).forEach(([key1, value1]) => {

                    // Assign schema to first control
                    if(schemaVersion === ""){
                        schemaVersion = value1["SchemaVersion"]
                    }
                    // Check schema for subsequent controls.
                    else{
                        if(schemaVersion !== value1["SchemaVersion"]){
                            schemaConsistent = false;
                            return;
                        }
                    }

                    if((value1["ControlSettings"]!==undefined || value1["AssessmentProperties"]!==undefined || value1["CustomPolicyProperties"]!==undefined || value1["CustomDeploymentPolicyProperties"]!==undefined || value1["Enabled"]!==undefined)){
                        controls = controls.concat({
                            "Id": key1,
                            "ControlID": value1["ControlId"] ? value1["ControlId"] : null,
                            "Fields": value1["Fields"] ? value1["Fields"] : null,
                            "Description": value1["Description"] ? value1["Description"] : null,
                            "CustomTags": value1['CustomTags'] ? value1['CustomTags']: null,
                            "ControlRequirements": value1['ControlRequirements'] ? value1['ControlRequirements'] : null,
                            "ControlSeverity": value1['ControlSeverity'] ? value1['ControlSeverity'] : null,
                            "ControlScanSource": value1['ControlScanSource'] ? value1['ControlScanSource'] : null,
                            "OriginalScanSource": value1['OriginalScanSource'] ? value1['OriginalScanSource'] : null,
                            "DisplayName": value1['DisplayName'] ? value1['DisplayName'] : null,
                            "Category": value1['Category'] ? value1['Category'] : null,
                            "Rationale": value1['Rationale'] ? value1['Rationale'] : null,
                            "Recommendation": value1['Recommendation'] ? value1['Recommendation'] : null,
                            "ControlSettings" : value1["ControlSettings"] ? JSON.parse(value1["ControlSettings"]) : null,
                            "AssessmentProperties" : value1["AssessmentProperties"] ? JSON.parse(value1["AssessmentProperties"]) : null,
                            "CustomPolicyProperties" : value1["CustomPolicyProperties"] ? JSON.parse(value1["CustomPolicyProperties"]) : null,
                            "CustomDeploymentPolicyProperties" : value1["CustomDeploymentPolicyProperties"] ? JSON.parse(value1["CustomDeploymentPolicyProperties"]) : null,
                            "Enabled" :  value1["Enabled"] !== undefined && value1["Enabled"] !== null ? value1["Enabled"].toString() : null,
                            "SchemaVersion" : value1["SchemaVersion"]
                        });
                        privilegedRequest = true;                
                    }
                    else{
                        controls = controls.concat({                          
                            "Id": key1,
                            "ControlID": value1["ControlId"] ? value1["ControlId"] : null,
                            "Fields": value1["Fields"] ? value1["Fields"] : null,
                            "Description": value1["Description"] ? value1["Description"] : null,
                            "CustomTags": value1['CustomTags'] ? value1['CustomTags']: null,
                            "ControlRequirements": value1['ControlRequirements'] ? value1['ControlRequirements'] : null,
                            "ControlSeverity": value1['ControlSeverity'] ? value1['ControlSeverity'] : null,
                            "ControlScanSource": value1['ControlScanSource'] ? value1['ControlScanSource'] : null,
                            "OriginalScanSource": value1['OriginalScanSource'] ? value1['OriginalScanSource'] : null,
                            "DisplayName": value1['DisplayName'] ? value1['DisplayName'] : null,
                            "Category": value1['Category'] ? value1['Category'] : null,
                            "Rationale": value1['Rationale'] ? value1['Rationale'] : null,
                            "Recommendation": value1['Recommendation'] ? value1['Recommendation'] : null,
                            "SchemaVersion" : value1["SchemaVersion"]
                        });        
                    }
                    
                });

                if(schemaConsistent === false){
                    return;
                }

                features = features.concat({
                    "Feature": key,
                    "Controls": controls,
                });
            });
            var changelog : ChangeLog[] = [];
            changelog = changelog.concat({
                user: "",
                date: "",
                comment: comments.length===0?"[Editing existing control] ":comments
            });

            var request = {};
            request['Features'] = features;
            request['ChangeLog'] = changelog;
            var apiEndpoint = "updatecontrols/"

            if(privilegedRequest){
                apiEndpoint = 'privilegedupdatecontrols/'
            }
            
            if(!schemaConsistent){
                setShowMessageError(true);
                setSpinner(false);

            }
            else{
                fetch((ScannerAPI + '/controleditor/' + apiEndpoint), {
                    headers: !authToken.accessToken ? {} : {
                        'Authorization': `Bearer ${authToken.accessToken}`,
                        'u_Id': sessionStorage.getItem("u_Id"),
                        'Content-Type': 'application/json'
                    },
                    method: 'POST', 
                    body: JSON.stringify(request)
                }).then(response => {
                    if (response.ok) {
                        setChangedData({});
                        setShowMessage(true);
                        setSpinner(false);
                        setSubmitted(true);
                    }
                    else {
                        setSpinner(false);
                        toast("Unable to update the selected controls.");
                    }
                });  
            }
        }

        CallAPI()
    }

    useEffect(() => {
        var tableDisplayList = [];
        var increment = 0;
        for (var feature in changedData) {
            for (var control in changedData[feature]) {
                tableDisplayList = tableDisplayList.concat({
                    "key": increment,
                    "FeatureName": feature,
                    "ControlID": changedData[feature][control]["ControlId"]
                });
            }
        }            
        setDisplayTable(tableDisplayList);
    },
    // eslint-disable-next-line
    [modelOpenUpdate]);

    
    const onChangeText = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(newValue.length <= 1000) {
            setComments(newValue);
        }
    };

    const onDismissModal = (): void => {
        toggleModelOpenUpdate();
        if(submitted){
            setShowMessage(false);
            setComments("");
        }
        setSpinner(false);
        setSubmitted(false);     
        setShowMessage(false);
        setShowMessageError(false);   
    }

    return (
        <div>
            <Modal
                isOpen={modelOpenUpdate}
                containerClassName={contentStyles.container2}
                onDismiss={onDismissModal}
                dragOptions={dragOptions}
            >
                <div className={contentStyles.header}>
                    <span>Update Control Metadata</span>
                    <IconButton
                        style={{outline:"none"}}
                        styles={iconButtonStyles}
                        iconProps={{iconName:'Cancel'}}
                        ariaLabel="Close popup modal"
                        onClick={onDismissModal}
                    />
                </div>
                <div className={contentStyles.body}>
                    <div>
                        The following Controls have been selected for update:
                    </div>
                    <DetailsList
                        items={displayTable}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                    />
                    <div className={_Styles.rowGap} />
                    <TextField
                        label={"Comments"}
                        multiline
                        value={comments}
                        errorMessage={comments ? comments.length <= 1000 ? "" : "Character limit reached" : ""}
                        rows={3}
                        onChange={onChangeText}
                    />
                    <div className={_Styles.rowGap} />
                    <div className={_Styles.rowGap} />
                    <div>
                        {(showMessage) && <div><SuccessExample/><div className={_Styles.rowGap} /></div>}
                    </div>
                    <div>
                        {(showMessageError) && <div><VersionMismatchExample/><div className={_Styles.rowGap} /></div>}
                    </div>
                    <Stack {...{horizontal: true, verticalAlign: 'center'}} horizontalAlign={"space-between"}
                        reversed style={{padding: 0, marginTop: 0}} tokens={{childrenGap: 10}}>
                        <Stack horizontal reversed tokens={{childrenGap: 10}}>
                            <DefaultButton onClick={onDismissModal} text="Close" />
                            <PrimaryButton onClick={submitData} text="Confirm" disabled={submitted} />
                        </Stack>
                        {spinner && <Spinner size={SpinnerSize.medium} label="Updating the controls..." labelPosition="right" /> }
                    </Stack>
                </div>    
                <div className={_Styles.rowGap} />     
            </Modal>
        </div>
    );
}