import { ITableIndividualRowState, ITableIndividualRowStateDevOps } from '../../Interface/ITable';

// eslint-disable-next-line
export function copyAndSort<T>(items: ITableIndividualRowState[], columnKey: string, isSortedDescending?: boolean): ITableIndividualRowState[] {
    const key = columnKey as keyof ITableIndividualRowState;

    const getValue = (item: ITableIndividualRowState, key: keyof ITableIndividualRowState) => {
        switch (key) {
            case "status":
                return item["status"]["status"].toString().toLowerCase();
            case "resourceObject":
                return item["resource"].toString().toLowerCase();
            case "rg":
                return item["rg"].toString().toLowerCase();
            case "controlObject":
                return item["controlObject"]["displayName"].toString().toLowerCase();
            default:
                return "";
        }
    };

    return items.slice(0).sort((a, b) => {
        const aValue = getValue(a, key);
        const bValue = getValue(b, key);
        if (aValue < bValue) return isSortedDescending ? 1 : -1;
        if (aValue > bValue) return isSortedDescending ? -1 : 1;
        return 0;
    });
}

export function copyAndSortDevOps<T>(items: ITableIndividualRowStateDevOps[], columnKey: string, isSortedDescending?: boolean): ITableIndividualRowStateDevOps[] {
    const key = columnKey as keyof ITableIndividualRowState;
    if(key === "status") {
        return items.slice(0).sort((a: ITableIndividualRowStateDevOps, b: ITableIndividualRowStateDevOps) => (
            (isSortedDescending ?
                a[key]["status"].toString().toLowerCase() < b[key]["status"].toString().toLowerCase() :
                a[key]["status"].toString().toLowerCase() > b[key]["status"].toString().toLowerCase()) ? 1 : -1));
    }

    return items.slice(0).sort((a: ITableIndividualRowStateDevOps, b: ITableIndividualRowStateDevOps) => (
            (isSortedDescending ?
                a[key].toString().toLowerCase() < b[key].toString().toLowerCase() :
                a[key].toString().toLowerCase() > b[key].toString().toLowerCase()) ? 1 : -1));
}