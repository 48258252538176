import React, { Component, createContext } from 'react';

export const HelpContext = createContext();

export class HelpContextProvider extends Component {
  state = {
    teachingBubbleVisiblePanel: false,
    teachingBubbleVisibleFeature: false,
    teachingBubbleVisibleSecurityDomain: false,
    teachingBubbleVisibleControlID: false,
    teachingBubbleVisibleScanSource: false,
    teachingBubbleVisibleName: false,
    teachingBubbleVisibleDescription: false,
    teachingBubbleVisibleTags: false,
    teachingBubbleVisibleSeverity: false,
    teachingBubbleVisibleCategory: false,
    teachingBubbleVisibleAutomated: false,
    teachingBubbleVisibleRequirements: false,
    teachingBubbleVisibleAssessment: false,
    teachingBubbleVisibleRationale: false,
    teachingBubbleVisibleRecommendation: false,
    teachingBubbleVisibleCustomTags: false,
    teachingBubbleVisibleEnabled: false,
    teachingBubbleVisibleSettings: false,
    teachingBubbleVisibleComments: false,
    teachingBubbleVisiblePolicyDefinitionMappings: false,
    teachingBubbleVisiblePolicyProperties: false,
    teachingBubbleVisibleMethodName: false,
    teachingBubbleVisibleLINQQuery: false,
    teachingBubbleVisibleRequiredField: false,
  }


  toggleTeachingBubbleVisibleLINQQuery = () => {
    this.setState({
      teachingBubbleVisibleLINQQuery: !this.state.teachingBubbleVisibleLINQQuery
    })
  }

  toggleTeachingBubbleVisibleRequiredField = () => {
    this.setState({
      teachingBubbleVisibleRequiredField: !this.state.teachingBubbleVisibleRequiredField
    })
  }
  
  toggleTeachingBubbleVisibleMethodName = () => {
    this.setState({
      teachingBubbleVisibleMethodName: !this.state.teachingBubbleVisibleMethodName
    })
  }

  toggleTeachingBubbleVisiblePanel = () => {
    this.setState({
      teachingBubbleVisiblePanel: !this.state.teachingBubbleVisiblePanel
    })
  }

  toggleTeachingBubbleVisibleFeature = () => {
    this.setState({
      teachingBubbleVisibleFeature: !this.state.teachingBubbleVisibleFeature
    })
  }
  
  toggleTeachingBubbleVisibleSecurityDomain = () => {
    this.setState({
      teachingBubbleVisibleSecurityDomain: !this.state.teachingBubbleVisibleSecurityDomain
    })
  }

  toggleTeachingBubbleVisibleControlID = () => {
    this.setState({
      teachingBubbleVisibleControlID: !this.state.teachingBubbleVisibleControlID
    })
  }

  toggleTeachingBubbleVisibleScanSource = () => {
    this.setState({
      teachingBubbleVisibleScanSource: !this.state.teachingBubbleVisibleScanSource
    })
  }

  toggleTeachingBubbleVisibleName = () => {
    this.setState({
      teachingBubbleVisibleName: !this.state.teachingBubbleVisibleName
    })
  }

  toggleTeachingBubbleVisibleDescription = () => {
    this.setState({
      teachingBubbleVisibleDescription: !this.state.teachingBubbleVisibleDescription
    })
  }
  
  toggleTeachingBubbleVisibleTags = () => {
    this.setState({
      teachingBubbleVisibleTags: !this.state.teachingBubbleVisibleTags
    })
  }

  toggleTeachingBubbleVisibleSeverity = () => {
    this.setState({
      teachingBubbleVisibleSeverity: !this.state.teachingBubbleVisibleSeverity
    })
  }

  toggleTeachingBubbleVisibleCategory = () => {
    this.setState({
      teachingBubbleVisibleCategory: !this.state.teachingBubbleVisibleCategory
    })
  }

  toggleTeachingBubbleVisibleAutomated = () => {
    this.setState({
      teachingBubbleVisibleAutomated: !this.state.teachingBubbleVisibleAutomated
    })
  }

  toggleTeachingBubbleVisibleRequirements = () => {
    this.setState({
      teachingBubbleVisibleRequirements: !this.state.teachingBubbleVisibleRequirements
    })
  }

  toggleTeachingBubbleVisibleAssessment = () => {
    this.setState({
      teachingBubbleVisibleAssessment: !this.state.teachingBubbleVisibleAssessment
    })
  }

  toggleTeachingBubbleVisibleRationale = () => {
    this.setState({
      teachingBubbleVisibleRationale: !this.state.teachingBubbleVisibleRationale
    })
  }

  toggleTeachingBubbleVisibleRecommendation = () => {
    this.setState({
      teachingBubbleVisibleRecommendation: !this.state.teachingBubbleVisibleRecommendation
    })
  }

  toggleTeachingBubbleVisibleCustomTags = () => {
    this.setState({
      teachingBubbleVisibleCustomTags: !this.state.teachingBubbleVisibleCustomTags
    })
  }

  toggleTeachingBubbleVisibleEnabled = () => {
    this.setState({
      teachingBubbleVisibleEnabled: !this.state.teachingBubbleVisibleEnabled
    })
  }

  toggleTeachingBubbleVisibleSettings = () => {
    this.setState({
      teachingBubbleVisibleSettings: !this.state.teachingBubbleVisibleSettings
    })
  }

  toggleTeachingBubbleVisibleComments = () => {
    this.setState({
      teachingBubbleVisibleComments: !this.state.teachingBubbleVisibleComments
    })
  }

  toggleTeachingBubbleVisiblePolicyDefinitionMappings = () => {
    this.setState({
      teachingBubbleVisiblePolicyDefinitionMappings: !this.state.teachingBubbleVisiblePolicyDefinitionMappings
    })
  }

  toggleTeachingBubbleVisiblePolicyProperties = () => {
    this.setState({
      teachingBubbleVisiblePolicyProperties: !this.state.teachingBubbleVisiblePolicyProperties
    })
  }
  
  render() { 
    return (
      <HelpContext.Provider value={{
        ...this.state, 
        toggleTeachingBubbleVisiblePanel: this.toggleTeachingBubbleVisiblePanel,
        toggleTeachingBubbleVisibleFeature: this.toggleTeachingBubbleVisibleFeature, 
        toggleTeachingBubbleVisibleSecurityDomain: this.toggleTeachingBubbleVisibleSecurityDomain, 
        toggleTeachingBubbleVisibleControlID: this.toggleTeachingBubbleVisibleControlID, 
        toggleTeachingBubbleVisibleScanSource: this.toggleTeachingBubbleVisibleScanSource, 
        toggleTeachingBubbleVisibleName: this.toggleTeachingBubbleVisibleName, 
        toggleTeachingBubbleVisibleDescription: this.toggleTeachingBubbleVisibleDescription,
        toggleTeachingBubbleVisibleTags: this.toggleTeachingBubbleVisibleTags, 
        toggleTeachingBubbleVisibleSeverity: this.toggleTeachingBubbleVisibleSeverity, 
        toggleTeachingBubbleVisibleCategory: this.toggleTeachingBubbleVisibleCategory, 
        toggleTeachingBubbleVisibleAutomated: this.toggleTeachingBubbleVisibleAutomated, 
        toggleTeachingBubbleVisibleRequirements: this.toggleTeachingBubbleVisibleRequirements, 
        toggleTeachingBubbleVisibleAssessment: this.toggleTeachingBubbleVisibleAssessment, 
        toggleTeachingBubbleVisibleRationale: this.toggleTeachingBubbleVisibleRationale, 
        toggleTeachingBubbleVisibleRecommendation: this.toggleTeachingBubbleVisibleRecommendation,
        toggleTeachingBubbleVisibleCustomTags: this.toggleTeachingBubbleVisibleCustomTags, 
        toggleTeachingBubbleVisibleEnabled: this.toggleTeachingBubbleVisibleEnabled, 
        toggleTeachingBubbleVisibleSettings: this.toggleTeachingBubbleVisibleSettings, 
        toggleTeachingBubbleVisibleComments: this.toggleTeachingBubbleVisibleComments, 
        toggleTeachingBubbleVisiblePolicyDefinitionMappings: this.toggleTeachingBubbleVisiblePolicyDefinitionMappings,
        toggleTeachingBubbleVisiblePolicyProperties: this.toggleTeachingBubbleVisiblePolicyProperties,
        toggleTeachingBubbleVisibleMethodName: this.toggleTeachingBubbleVisibleMethodName,
        toggleTeachingBubbleVisibleLINQQuery: this.toggleTeachingBubbleVisibleLINQQuery,
        toggleTeachingBubbleVisibleRequiredField: this.toggleTeachingBubbleVisibleRequiredField
      }}
      >
        {this.props.children}
      </HelpContext.Provider>
    );
  }
}
 
export default HelpContextProvider;
