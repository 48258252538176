import React, { useEffect, useContext, useState } from "react";
import {
  contentStyles,
  dragOptions,
  iconButtonStyles,
} from "../Styles/Dialog/CommonControlEditor";
import {
  MessageBar,
  MessageBarType,
  Modal,
  Stack,
  TextField,
  PrimaryButton,
  DefaultButton,
  IconButton,
  FontIcon,
  Text,
  Spinner,
  SpinnerSize,
  Link,
  loadTheme,
} from "@fluentui/react";
import { _Styles } from "../Page.styles";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import {
  IComboBoxOption,
  ComboBox,
  IComboBox,
  IComboBoxStyles,
  VirtualizedComboBox,
} from "@fluentui/react";
import { panelClassNames } from "./PersonaPanel";
import { AdminContext } from "../context/ControlEditor";
import { HelpContext } from "../context/HelpContextControlEditor";
import {
  Dropdown,
  IDropdownStyles,
  IDropdownOption,
} from "@fluentui/react/lib/Dropdown";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { authProvider } from "../../authProvider";
import { toast } from "react-toastify";
import { ChangeLog } from "../Interface/IAdminTable";
import { SharedColors } from "@fluentui/theme";
import { HelpTable } from "../ControlEditor/TeachingBubbleControlEditor";
import { CollapsibleSection } from "@fluentui/react-experiments";
import { controlEditorTheme } from "../Themes/ControlEditorTheme";
import { isJsonString } from "../Helper/JsonHelper";

const theme = loadTheme(controlEditorTheme);

const dropdown_template: IDropdownOption[] = [];

export interface PolicyDefinitionMapping {
  id: string;
  policyDefinitionId: string;
  assignmentId: string;
}

const generateId = (): string => {
  return Math.random().toString(36).substring(2, 7);
};

const optionsMethodName = [
  {
    key: "EvaluateControlbyReaderUsingLINQAsync",
    text: "Evaluate Control Using LINQ",
  },
] as const;

type MethodName = (typeof optionsMethodName)[number]["key"];

/** Functional component for the panel to add a new Control to the AzTS solution */
export const NewControl: React.FunctionComponent = () => {
  const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
  const RuntimeConfigurationExtended =
    window.__UI_CONFIGURATION_EXTENDED_AZURE__;
  const ScannerAPI = RuntimeConfigurationInitial.webAPIConf
    ? RuntimeConfigurationInitial.webAPIConf["AZURE"]
    : RuntimeConfigurationInitial.webAPI;

  const { mdcModelOpenUpdate, toggleMDCModelOpenUpdate } =
    useContext(AdminContext);

  const {
    toggleTeachingBubbleVisibleFeature,
    toggleTeachingBubbleVisibleSecurityDomain,
    toggleTeachingBubbleVisibleControlID,
    toggleTeachingBubbleVisibleScanSource,
    toggleTeachingBubbleVisibleName,
    toggleTeachingBubbleVisibleDescription,
    toggleTeachingBubbleVisibleTags,
    toggleTeachingBubbleVisibleSeverity,
    toggleTeachingBubbleVisibleCategory,
    toggleTeachingBubbleVisibleAutomated,
    toggleTeachingBubbleVisibleRequirements,
    toggleTeachingBubbleVisibleAssessment,
    toggleTeachingBubbleVisibleRationale,
    toggleTeachingBubbleVisibleRecommendation,
    toggleTeachingBubbleVisibleCustomTags,
    toggleTeachingBubbleVisibleEnabled,
    toggleTeachingBubbleVisibleComments,
    toggleTeachingBubbleVisiblePolicyDefinitionMappings,
    toggleTeachingBubbleVisiblePolicyProperties,
    toggleTeachingBubbleVisibleMethodName,
    toggleTeachingBubbleVisibleLINQQuery,
    toggleTeachingBubbleVisibleRequiredField,
    toggleTeachingBubbleVisibleSettings,
  } = useContext(HelpContext);

  const [controlID, setControlID] = useState("");

  const [description, setDescription] = useState("");
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [showOptional, setShowOptional] = useState<boolean>(false);

  const [customPolicyProperties, setCustomPolicyProperties] = useState("{}");

  const [policyDefinitionMappings, setPolicyDefinitionMappings] = useState<
    PolicyDefinitionMapping[]
  >([{ id: generateId(), policyDefinitionId: "", assignmentId: "" }]);

  const [assessmentProperties, setAssessmentProperties] = useState("{}");
  const [controlRequirements, setControlRequirements] = useState("");

  const [recommendation, setRecommendation] = useState("");
  const [severity, setSeverity] = useState("Medium");

  const [category, setCategory] = useState("");
  const [scanSource, setScanSource] = useState("");

  const [enabled, setEnabled] = useState(true);
  const [displayName, setDisplayName] = useState("");

  const [rationale, setRationale] = useState("");
  const [controlSettings, setControlSettings] = useState("{}");

  const [submitted, setSubmitted] = useState(false);
  const [automated, setAutomated] = useState("Yes");

  const [resourceOptions, setResourceOptions] = useState<IDropdownOption[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<IDropdownOption[]>([]);

  const [methodName, setMethodName] = useState<MethodName>(
    optionsMethodName[0].key
  );
  const [customTags, setCustomTags] = useState<string[]>([]);
  const [customTagsOptions, setCustomTagsOptions] = useState<IComboBoxOption[]>(
    []
  );

  const [allTags, setAllTags] = useState<string[]>(["Baseline", "Automated"]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [feature, setFeature] = useState("");
  const [isDialogVisible, setIsDialogVisible] = React.useState(false);

  const [comment, setComment] = useState("");
  const [changed, setChanged] = useState(false);

  const [spinner, setSpinner] = React.useState<boolean>(false);
  const [isRequiredMDC, setIsRequiredMDC] = useState(false);

  const [isRequiredReader, setIsRequiredReader] = useState<boolean>(false);

  const [securityDomain, setSecurityDomain] = useState("");
  const [securityDomainOptions, setSecurityDomainOptions] = useState<
    IDropdownOption[]
  >([]);

  const [displayNamePrefix, setDisplayNamePrefix] = useState("");
  const [assessmentNameOptions, setAssessmentNameOptions] = useState<
    IDropdownOption[]
  >([]);

  const [resourceType, setResourceType] = useState("");
  const [features, setFeatures] = useState<string[]>([]);
  const [isFeatureNew, setIsFeatureNew] = useState<boolean>(false);
  const [isFeatureEmpty, setIsFeatureEmpty] = useState<boolean>(true);
  const [isSecurityDomainEmpty, setIsSecurityDomainEmpty] =
    useState<boolean>(true);

  const [assessment, setAssessment] = useState(new Map());

  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 500 },
  };

  const comboBoxStyles: Partial<IComboBoxStyles> = {
    root: { maxWidth: 500 },
  };

  const optionsSeverity: IDropdownOption[] = [
    { key: "High", text: "High" },
    { key: "Medium", text: "Medium" },
    { key: "Low", text: "Low" },
  ];

  const optionsScanSource: IDropdownOption[] = [
    { key: "mdc", text: "MDC" },
    ...(RuntimeConfigurationExtended.controlEditorFeatureConfiguration
      .isAddNewReaderBasedControlEnabled
      ? [{ key: "reader", text: "Reader", disabled: false }]
      : []),
    { key: "mdcandreader", text: "MDCandReader", disabled: true },
    { key: "mdcorreader", text: "MDCorReader", disabled: true },
    { key: "customPolicy", text: "Policy" },
  ];

  const optionsSecurityDomain: IComboBoxOption[] = [
    { key: "Audit", text: "Audit (Audit)" },
    { key: "AuthN", text: "Authentication (AuthN)" },
    { key: "AuthZ", text: "Authorization (AuthZ)" },
    { key: "DP", text: "Data Protection (DP)" },
    { key: "Deploy", text: "Deployment (Deploy)" },
    { key: "NetSec", text: "Network Security (NetSec)" },
    { key: "RBAC", text: "Role Based Access Control (RBAC)" },
    { key: "SI", text: "System Integrity (SI)" },
  ];

  const optionsTags: IComboBoxOption[] = [
    { key: "Baseline", text: "Baseline", disabled: true, selected: true },
    { key: "Automated", text: "Automated", disabled: true, selected: true },
  ];
  const onEnable = React.useCallback(
    (
      event?: React.FormEvent<HTMLElement | HTMLInputElement>,
      checked?: boolean
    ): void => {
      setEnabled(checked);
      setChanged(true);
    },
    []
  );

  const onAutomated = React.useCallback(
    (
      event?: React.FormEvent<HTMLElement | HTMLInputElement>,
      checked?: boolean
    ): void => {
      if (checked) setAutomated("Yes");
      else setAutomated("No");
      setChanged(true);
    },
    []
  );
  const onRenderHeader = React.useCallback(
    () => (
      <Stack style={{ marginBottom: 10 }}>
        <Stack
          style={{ marginLeft: 25, marginRight: 25 }}
          tokens={{ childrenGap: -5 }}
        >
          <Stack horizontal horizontalAlign="space-between">
            <Stack horizontal>
              <Text variant="xxLarge" id="panel-info">
                Add New Control
              </Text>
              {changed === true && (
                <FontIcon
                  iconName="AsteriskSolid"
                  style={{ color: SharedColors.redOrange10, fontSize: 12 }}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    ),
    // eslint-disable-next-line
    [changed]
  );

  const onRenderFooterContent = React.useCallback(
    () => (
      <Stack horizontal reversed horizontalAlign="space-between">
        <Stack horizontal tokens={{ childrenGap: 15 }}>
          <PrimaryButton
            onClick={submitControl}
            text="Add"
            disabled={submitted || !areAllFieldsFilled()}
          />
          <DefaultButton onClick={onDismissModal} text="Close" />
        </Stack>
        {showMessage && (
          <div>
            <SuccessExample />
            <div className={_Styles.rowGap} />
          </div>
        )}
        {spinner && (
          <Spinner
            size={SpinnerSize.medium}
            label="Adding the new control..."
            labelPosition="right"
          />
        )}
      </Stack>
    ),
    // eslint-disable-next-line
    [
      changed,
      feature,
      securityDomain,
      controlID,
      description,
      severity,
      displayName,
      category,
      automated,
      controlRequirements,
      scanSource,
      assessmentProperties,
      rationale,
      recommendation,
      customTags,
      allTags,
      enabled,
      controlSettings,
      comment,
      spinner,
      showMessage,
      customPolicyProperties,
      methodName,
    ]
  );

  const SuccessExample = () => (
    <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
      <div>The new control has been added successfully.</div>
      <div>The control will be reflected in the next scan.</div>
    </MessageBar>
  );

  const onChangeCustomTags = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ): void => {
    let selected = option?.selected;
    if (!option && value) {
      selected = true;
      option = { key: value, text: value };
      setCustomTagsOptions((customTagsOptions) => [
        ...customTagsOptions,
        option!,
      ]);
    }

    if (option) {
      setCustomTags((prevSelectedKeys) =>
        selected
          ? [...prevSelectedKeys, option!.text as string]
          : prevSelectedKeys.filter((k) => k !== option!.text)
      );
    }
    setChanged(true);
  };

  const onChangeTags = (
    ev: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ): void => {
    let selected = option?.selected;
    if (option) {
      setSelectedTags((prevSelectedKeys) =>
        selected
          ? [...prevSelectedKeys, option!.key as string]
          : prevSelectedKeys.filter((k) => k !== option!.key)
      );
    }
    setChanged(true);
  };

  const onChangeSeverity = (
    event: React.FormEvent<HTMLDivElement>,
    selectedOption?: IDropdownOption
  ): void => {
    setSeverity(selectedOption.text);
    setChanged(true);
  };

  const onChangeFeature = React.useCallback(
    (
      event: React.FormEvent<IComboBox>,
      option?: IComboBoxOption,
      index?: number,
      value?: string
    ): void => {
      let key = option?.key;
      if (!option && value) {
        key = value;
        setResourceOptions((prevOptions) => [
          ...prevOptions,
          { key: key, text: value },
        ]);
      }
      if (key) {
        setFeature(key as string);
      }
      setChanged(true);
    },
    []
  );

  const onChangeAssessmentName = React.useCallback(
    (
      event: React.FormEvent<IComboBox>,
      option?: IComboBoxOption,
      index?: number,
      value?: string
    ): void => {
      let key = option?.key;
      if (!option && value) {
        key = value;
        setAssessmentNameOptions((prevOptions) => [
          ...prevOptions,
          { key: key, text: value },
        ]);
      }
      setDisplayName(key as string);
      if (assessment.has(key)) {
        const assessmentIdArray = new Array(assessment.get(key));
        setAssessmentProperties(
          JSON.stringify({
            AssessmentNames: assessmentIdArray,
          })
        );
      } else setAssessmentProperties("{}");
      setChanged(true);
    },
    [assessment]
  );

  const onChangeCategory = React.useCallback(
    (
      event: React.FormEvent<IComboBox>,
      option?: IComboBoxOption,
      index?: number,
      value?: string
    ): void => {
      let key = option?.key;
      if (!option && value) {
        key = value;
        setCategoryOptions((prevOptions) => [
          ...prevOptions,
          { key: key!, text: value },
        ]);
      }

      setCategory(key as string);
      setChanged(true);
    },
    []
  );

  const onChangeSecurityDomain = React.useCallback(
    (
      event: React.FormEvent<IComboBox>,
      option?: IComboBoxOption,
      index?: number,
      value?: string
    ): void => {
      let key = option?.key;
      if (!option && value) {
        key = value;
        setSecurityDomainOptions((prevOptions) => [
          ...prevOptions,
          { key: key!, text: value },
        ]);
      }

      setSecurityDomain(key as string);
      setChanged(true);
      if (!value || value === "") setIsSecurityDomainEmpty(true);
      else setIsSecurityDomainEmpty(false);
    },
    []
  );

  const onChangeScanSource = (
    event: React.FormEvent<HTMLDivElement>,
    selectedOption?: IDropdownOption
  ): void => {
    const { key, text } = selectedOption || {};

    setScanSource(text);
    setIsRequiredMDC(key === "mdc");
    setIsRequiredReader(key === "reader");
    setCustomPolicyProperties(
      key === "customPolicy"
        ? '{"PolicyDefinitionandAssignmentIdMapping":[{"PolicyDefinitionId":"","AssignmentId":""}]}'
        : "{}"
    );
    setChanged(true);
  };

  const onChangeControlID = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setControlID(newValue);
    setChanged(true);
  };

  const onChangeResourceType = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setResourceType(newValue);
    setChanged(true);
  };

  const onChangeRationale = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setRationale(newValue);
    setChanged(true);
  };

  const onChangeDisplayName = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setDisplayName(newValue);
    setChanged(true);
  };

  const onChangeDescription = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setDescription(newValue);
    setChanged(true);
  };

  const onChangeMethodName = (
    event: React.FormEvent<HTMLDivElement>,
    selectedOption?: IDropdownOption
  ): void => {
    const key = selectedOption?.key;
    if (key) setMethodName(String(key) as MethodName);
    setChanged(true);
  };

  const onChangeRecommendation = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setRecommendation(newValue);
    setChanged(true);
  };

  const onChangeControlSettings = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setControlSettings(newValue);
    setChanged(true);
  };

  const onChangeCustomPolicyProperties = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setCustomPolicyProperties(newValue);
    setChanged(true);
  };

  const onChangeControlRequirements = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setControlRequirements(newValue);
    setChanged(true);
  };

  const onChangeAssesmentProperties = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setAssessmentProperties(newValue);
    setChanged(true);
  };

  const onChangeComment = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setComment(newValue);
    setChanged(true);
  };

  const addNewMapping = () => {
    setPolicyDefinitionMappings([
      ...policyDefinitionMappings,
      { id: generateId(), policyDefinitionId: "", assignmentId: "" },
    ]);
  };

  const deleteMapping = (id: string) => {
    setPolicyDefinitionMappings(
      policyDefinitionMappings.filter((mapping) => mapping.id !== id)
    );
  };

  const updatePolicyDefinitionMapping = (
    id: string,
    field: "policyDefinitionId" | "assignmentId",
    value: string
  ) => {
    setPolicyDefinitionMappings(
      policyDefinitionMappings.map((mapping) =>
        mapping.id === id ? { ...mapping, [field]: value } : mapping
      )
    );
  };

  useEffect(() => {
    const mappings = policyDefinitionMappings
      .filter(
        (mapping) =>
          mapping.policyDefinitionId.length > 0
      )
      .map((mapping) => {
        return {
          PolicyDefinitionId: mapping.policyDefinitionId,
          AssignmentId: mapping.assignmentId,
        };
      });
    setCustomPolicyProperties(
      JSON.stringify({ PolicyDefinitionandAssignmentIdMapping: mappings })
    );
  }, [policyDefinitionMappings]);

  const hideDialog = React.useCallback(() => setIsDialogVisible(false), []);

  const hideModal = (): void => {
    toggleMDCModelOpenUpdate();
    setControlID("");
    setDescription("");
    setControlID("");
    setDisplayName("");
    setRationale("");
    setChanged(false);
    setSeverity("Medium");
    setFeature("");
    setIsDialogVisible(false);
    setRecommendation("");
    setSubmitted(false);
    setSpinner(false);
    setComment("");
    setCategory("");
    setControlRequirements("");
    setAssessmentProperties("{}");
    setControlSettings("{}");
    setSecurityDomain("");
    setShowMessage(false);
    setDisplayNamePrefix("");
    setEnabled(true);
    setCustomTags([]);
    setCustomTagsOptions([]);
    setCustomPolicyProperties("{}");
    setPolicyDefinitionMappings([{ id: generateId(), policyDefinitionId: "", assignmentId: "" }]);
    setResourceType("");
    setMethodName("EvaluateControlbyReaderUsingLINQAsync");
  };

  const onDismissModal = (): void => {
    if (changed === true) {
      setIsDialogVisible(true);
    } else {
      hideModal();
    }
  };

  const areAllFieldsFilled = (): boolean => {
    const areCommonFieldsFilled = (): boolean => {
      const commonFields = [
        feature,
        securityDomain,
        controlID,
        displayName,
        category,
        scanSource,
      ];

      if (commonFields.some((field) => field.length <= 0)) {
        return false;
      }

      if (isFeatureNew && resourceType.length <= 0) {
        return false;
      }

      const jsonFields = [
        assessmentProperties,
        controlSettings,
        customPolicyProperties,
      ];

      return jsonFields.every(isJsonString);
    };

    if (!areCommonFieldsFilled()) {
      return false;
    }

    const isReaderSourceValid = (): boolean => {
      if (
        methodName.trim().length === 0 ||
        controlSettings.trim().length === 0
      ) {
        return false;
      }

      if (methodName === "EvaluateControlbyReaderUsingLINQAsync") {
        const settings = JSON.parse(controlSettings);
        return settings.LINQQuery && settings.RequiredFields;
      }

      return true;
    };

    const isPolicySourceValid = (): boolean => {

        if ( policyDefinitionMappings.length == 0) {
            return false;
        }
        else if ( policyDefinitionMappings.some((mapping) => mapping.policyDefinitionId.length != 0)) {
            return true;
        }

        return false;
    }

    switch (scanSource) {
      case "Reader":
        return isReaderSourceValid();

      case "Policy":
        return isPolicySourceValid();

      case "MDC":
        return assessmentProperties.length <= 0 ? false : true;

      default:
        return false;
    }
  };

  /**
   * Function to call new control addition API.
   * @remarks
   * Takes inputs from the fields in the UI, converts them to JSON and creates a POST request.
   * Only accessible to privilaged users.
   */
  const submitControl = (): void => {
    setSubmitted(true);
    setSpinner(true);

    const CallAPI = async (userServicePrincipal: string) => {
      const authToken = await authProvider.getAccessToken({
        scopes: [
          "api://" +
            RuntimeConfigurationInitial.apiClientId +
            "/user_impersonation",
        ],
      });

      var changelog: ChangeLog[] = [];
      changelog = changelog.concat({
        user: userServicePrincipal,
        date: new Date().toUTCString(),
        comment: comment.length === 0 ? "[Adding new Control] " : comment,
      });

      var newControl = {
        ControlId: "Azure_" + feature + "_" + securityDomain + "_" + controlID,
        Description: description,
        DisplayName: displayNamePrefix + displayName,
        Tags: [
          ...allTags,
          securityDomain,
          automated === "Yes" ? "Automated" : "Manual",
        ], //Add a tag based on selection
        CustomTags: customTags,
        ControlRequirements: controlRequirements,
        Category: category,
        MethodName: methodName,
        ControlSettings:
          controlSettings !== "{}" ? JSON.parse(controlSettings) : null,
        AssessmentProperties:
          assessmentProperties !== "{}"
            ? JSON.parse(assessmentProperties)
            : null,
        CustomPolicyProperties:
          customPolicyProperties !== "{}"
            ? JSON.parse(customPolicyProperties)
            : null,
        Enabled: enabled ? "true" : "false",
        ControlSeverity: severity,
        ControlScanSource: scanSource,
        Rationale: rationale,
        Recommendation: recommendation,
        Automated: automated,
      };
      let body = {
        Control: newControl,
        Feature: feature,
        ResourceType: resourceType,
        ChangeLog: changelog,
      };
      fetch(ScannerAPI + "/controleditor/addnewcontrol", {
        headers: !authToken.accessToken
          ? {}
          : {
              Authorization: `Bearer ${authToken.accessToken}`,
              u_Id: sessionStorage.getItem("u_Id"),
              "Content-Type": "application/json",
            },
        method: "POST",
        body: JSON.stringify(body),
      }).then((response) => {
        if (response.ok) {
          setSpinner(false);
          setShowMessage(true);
          setChanged(false);
        } else {
          setSubmitted(false);
          setSpinner(false);
          toast("Unable to add new control.");
        }
      });
    };
    const getAuthToken = async () => {
      let headers = new Headers();
      let token = (await authProvider.getAccessToken()).accessToken;
      headers.append("Authorization", token.toString());
      headers.append("x-content-type-options", "nosniff");
      return headers;
    };
    getAuthToken().then((headers) => {
      var api_details = RuntimeConfigurationExtended.graphAPI + "/v1.0/me";
      fetch(api_details, {
        headers: headers,
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          setSubmitted(true);
          CallAPI(data.userPrincipalName);
        });
    });
  };

  /**
   * @remarks
   * React hook to automatically add Trial or Preview to the Display name based on ControlID for maintaining solution health.
   */
  useEffect(() => {
    if (
      controlID.length >= 6 &&
      controlID.substring(controlID.length - 6, controlID.length) === "_Trial"
    ) {
      setDisplayNamePrefix("[Trial] ");
    } else if (
      controlID.length >= 8 &&
      controlID.substring(controlID.length - 8, controlID.length) === "_Preview"
    ) {
      setDisplayNamePrefix("[Preview] ");
    } else {
      setDisplayNamePrefix("");
    }
  }, [controlID]);

  useEffect(() => {
    if (features.includes(feature) || feature === "") {
      setIsFeatureNew(false);
    } else {
      setIsFeatureNew(true);
    }
  }, [feature]);

  /**
   * Function for getting values for features,category and assessment details from API to populate in the UI
   * @remarks
   * The data is automatically cached in the API and is updated once a day
   */
  const getInputs = React.useCallback((dataString: string) => {
    setSecurityDomainOptions(optionsSecurityDomain);

    var data = JSON.parse(dataString);
    var new_items = [...dropdown_template];
    // eslint-disable-next-line
    for (var i = 0; i < data.categories.length; i++) {
      new_items = new_items.concat({
        key: data.categories[i],
        text: data.categories[i],
      });
    }
    setCategoryOptions(new_items);

    new_items = [...dropdown_template];
    // eslint-disable-next-line
    for (var i = 0; i < data.resourceType.length; i++) {
      new_items = new_items.concat({
        key: data.resourceType[i],
        text: data.resourceType[i],
      });
    }
    setResourceOptions(new_items);

    new_items = [...dropdown_template];
    for (var i = 0; i < data.assessmentName.length; i++) {
      new_items = new_items.concat({
        key: data.assessmentName[i],
        text: data.assessmentName[i],
      });
    }
    setAssessmentNameOptions(new_items);

    var assessmentMap = new Map();
    for (var i = 0; i < data.assessmentName.length; i++) {
      assessmentMap.set(data.assessmentName[i], data.assessmentId[i]);
    }
    setAssessment(assessmentMap);

    var featuresList = [];
    for (var i = 0; i < data.resourceType.length; i++) {
      featuresList = featuresList.concat(data.resourceType[i]);
    }
    setFeatures(featuresList);
  }, []);
  const fetchInputs = React.useCallback(() => {
    const CallAPI = async () => {
      const authToken = await authProvider.getAccessToken({
        scopes: [
          "api://" +
            RuntimeConfigurationInitial.apiClientId +
            "/user_impersonation",
        ],
      });
      fetch(ScannerAPI + "/controleditor/newcontroldetails/", {
        headers: !authToken.accessToken
          ? {}
          : {
              Authorization: `Bearer ${authToken.accessToken}`,
              u_Id: sessionStorage.getItem("u_Id"),
              "Content-Type": "application/json",
            },
        method: "POST",
      }).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            getInputs(JSON.stringify(data));
          });
        } else {
          response.text().then((errorMsg) => {
            console.log(errorMsg);
          });
        }
      });
    };
    CallAPI();
  }, []);

  useEffect(() => {
    fetchInputs();
  }, []);

  return (
    <div>
      <Modal
        isOpen={isDialogVisible}
        containerClassName={contentStyles.container3}
        onDismiss={hideDialog}
        dragOptions={dragOptions}
      >
        <div className={contentStyles.header}>
          <span>Quit Editing?</span>
          <IconButton
            style={{ outline: "none" }}
            styles={iconButtonStyles}
            iconProps={{ iconName: "Cancel" }}
            ariaLabel="Close popup modal"
            onClick={hideDialog}
          />
        </div>
        <div className={contentStyles.body}>
          <div>
            The changes you made will be lost. Are you sure you want to quit?
          </div>
          <Stack
            {...{ horizontal: true, verticalAlign: "center" }}
            horizontalAlign={"space-between"}
            reversed
            style={{ padding: 0, margin: 0 }}
            tokens={{ childrenGap: 10 }}
          >
            <Stack
              horizontal
              reversed
              tokens={{ childrenGap: 10 }}
              style={{ marginTop: 20 }}
            >
              <DefaultButton onClick={hideDialog} text="No" />
              <PrimaryButton onClick={hideModal} text="Yes" />
            </Stack>
          </Stack>
        </div>
      </Modal>

      <Panel
        isOpen={mdcModelOpenUpdate}
        isLightDismiss
        type={PanelType.custom}
        headerClassName={panelClassNames.headerClass}
        customWidth={"45%"}
        onRenderFooterContent={onRenderFooterContent}
        onRenderHeader={onRenderHeader}
        onDismiss={onDismissModal}
        hasCloseButton={false}
        headerText="Control Setting"
        isFooterAtBottom={true}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
      >
        <HelpTable />
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack tokens={{ childrenGap: 0 }}>
            <Stack horizontal tokens={{ childrenGap: 0 }}>
              <Text variant="medium" style={{ marginTop: 4, fontWeight: 600 }}>
                Feature
              </Text>
              <FontIcon
                iconName="AsteriskSolid"
                style={{
                  color: SharedColors.redOrange10,
                  fontSize: 6,
                  marginTop: 4,
                }}
              />
              <IconButton
                style={{ outline: "none" }}
                id="feature-info"
                iconProps={{
                  iconName: "Info",
                  styles: { root: { fontSize: 16 } },
                }}
                aria-label="Feature Info"
                onClick={toggleTeachingBubbleVisibleFeature}
              />
            </Stack>
            <ComboBox
              placeholder="Select an option or enter a value"
              options={resourceOptions}
              selectedKey={feature}
              styles={comboBoxStyles}
              useComboBoxAsMenuWidth={true}
              calloutProps={{
                calloutMaxHeight: 300,
              }}
              allowFreeform
              autoComplete="on"
              onChange={onChangeFeature}
            />
          </Stack>

          {isFeatureNew && (
            <Stack tokens={{ childrenGap: 0 }}>
              <Stack horizontal tokens={{ childrenGap: 0 }}>
                <Text
                  variant="medium"
                  style={{ marginTop: 4, fontWeight: 600 }}
                >
                  Resource Type
                </Text>
                <FontIcon
                  iconName="AsteriskSolid"
                  style={{
                    color: SharedColors.redOrange10,
                    fontSize: 6,
                    marginTop: 4,
                  }}
                />
                <IconButton
                  style={{ outline: "none" }}
                  id="resourceType-info"
                  iconProps={{
                    iconName: "Info",
                    styles: { root: { fontSize: 16 } },
                  }}
                  aria-label="Resource Type Info"
                  onClick={toggleTeachingBubbleVisibleFeature}
                />
              </Stack>
              <TextField
                placeholder="Enter a value. Example: Microsoft.Storage/storageAccounts"
                value={resourceType}
                onChange={onChangeResourceType}
              />
            </Stack>
          )}

          {feature && feature.length > 0 && (
            <Stack tokens={{ childrenGap: 0 }}>
              <Stack horizontal tokens={{ childrenGap: 0 }}>
                <Text
                  variant="medium"
                  style={{ marginTop: 4, fontWeight: 600 }}
                >
                  Security Domain
                </Text>
                <FontIcon
                  iconName="AsteriskSolid"
                  style={{
                    color: SharedColors.redOrange10,
                    fontSize: 6,
                    marginTop: 4,
                  }}
                />
                <IconButton
                  style={{ outline: "none" }}
                  id="domain-info"
                  iconProps={{
                    iconName: "Info",
                    styles: { root: { fontSize: 16 } },
                  }}
                  aria-label="Domain Info"
                  onClick={toggleTeachingBubbleVisibleSecurityDomain}
                />
              </Stack>
              <ComboBox
                placeholder="Select an option or enter a value"
                options={securityDomainOptions}
                selectedKey={securityDomain}
                styles={comboBoxStyles}
                allowFreeform
                onChange={onChangeSecurityDomain}
              />
            </Stack>
          )}

          {securityDomain && securityDomain.length > 0 && (
            <>
              <Stack tokens={{ childrenGap: 0 }}>
                <Stack horizontal tokens={{ childrenGap: 0 }}>
                  <Text
                    variant="medium"
                    style={{ marginTop: 4, fontWeight: 600 }}
                  >
                    Control ID
                  </Text>
                  <FontIcon
                    iconName="AsteriskSolid"
                    style={{
                      color: SharedColors.redOrange10,
                      fontSize: 6,
                      marginTop: 4,
                    }}
                  />
                  <IconButton
                    style={{ outline: "none" }}
                    id="controlId-info"
                    iconProps={{
                      iconName: "Info",
                      styles: { root: { fontSize: 16 } },
                    }}
                    aria-label="Control ID Info"
                    onClick={toggleTeachingBubbleVisibleControlID}
                  />
                </Stack>
                <TextField
                  prefix={"Azure_" + feature + "_" + securityDomain + "_"}
                  placeholder="[Avoid_Spaces]. Example: Azure_APIManagement_DP_Use_HTTPS_URL_Scheme"
                  value={controlID}
                  errorMessage={
                    controlID
                      ? controlID.length <= 128
                        ? ""
                        : "Character limit reached"
                      : ""
                  }
                  onChange={onChangeControlID}
                />
              </Stack>

              <Stack tokens={{ childrenGap: 0 }}>
                <Stack horizontal tokens={{ childrenGap: 0 }}>
                  <Text
                    variant="medium"
                    style={{ marginTop: 4, fontWeight: 600 }}
                  >
                    Control Scan Source
                  </Text>
                  <FontIcon
                    iconName="AsteriskSolid"
                    style={{
                      color: SharedColors.redOrange10,
                      fontSize: 6,
                      marginTop: 4,
                    }}
                  />
                  <IconButton
                    style={{ outline: "none" }}
                    id="scanSource-info"
                    iconProps={{
                      iconName: "Info",
                      styles: { root: { fontSize: 16 } },
                    }}
                    aria-label="Scan Source Info"
                    onClick={toggleTeachingBubbleVisibleScanSource}
                  />
                </Stack>
                <Dropdown
                  placeholder="Select an option"
                  options={optionsScanSource}
                  styles={dropdownStyles}
                  onChange={onChangeScanSource}
                />
              </Stack>

              <Stack tokens={{ childrenGap: 0 }}>
                <Stack horizontal tokens={{ childrenGap: 0 }}>
                  <Text
                    variant="medium"
                    style={{ marginTop: 4, fontWeight: 600 }}
                  >
                    Display Name
                  </Text>
                  <FontIcon
                    iconName="AsteriskSolid"
                    style={{
                      color: SharedColors.redOrange10,
                      fontSize: 6,
                      marginTop: 4,
                    }}
                  />
                  <IconButton
                    style={{ outline: "none" }}
                    id="displayName-info"
                    iconProps={{
                      iconName: "Info",
                      styles: { root: { fontSize: 16 } },
                    }}
                    aria-label="Display Name Info"
                    onClick={toggleTeachingBubbleVisibleName}
                  />
                  {isRequiredMDC && (
                    <Link
                      href="https://portal.azure.com/?feature.customportal=false#blade/Microsoft_Azure_Security/SecurityMenuBlade/5"
                      underline
                      target="_blank"
                      style={{ marginTop: 4, fontWeight: 600 }}
                    >
                      You can find MDC control recommendations for your
                      subscriptions here.
                    </Link>
                  )}
                </Stack>
                {isRequiredMDC && (
                  <VirtualizedComboBox
                    placeholder="Select an option or enter a value"
                    options={assessmentNameOptions}
                    prefix={displayNamePrefix === "" ? null : displayNamePrefix}
                    selectedKey={displayName}
                    styles={comboBoxStyles}
                    useComboBoxAsMenuWidth={true}
                    calloutProps={{
                      calloutMaxHeight: 300,
                    }}
                    allowFreeform
                    onChange={onChangeAssessmentName}
                  />
                )}
                {!isRequiredMDC && (
                  <TextField
                    prefix={displayNamePrefix === "" ? null : displayNamePrefix}
                    value={displayName}
                    errorMessage={
                      displayName
                        ? displayName.length <= 128
                          ? ""
                          : "Character limit reached"
                        : ""
                    }
                    onChange={onChangeDisplayName}
                  />
                )}
              </Stack>

              {isRequiredMDC && (
                <Stack tokens={{ childrenGap: 0 }}>
                  <Stack horizontal tokens={{ childrenGap: 0 }}>
                    <Text
                      variant="medium"
                      style={{ marginTop: 4, fontWeight: 600 }}
                    >
                      Assessment Properties
                    </Text>
                    <IconButton
                      style={{ outline: "none" }}
                      id="assessment-info"
                      iconProps={{
                        iconName: "Info",
                        styles: { root: { fontSize: 16 } },
                      }}
                      aria-label="Assessment Info"
                      onClick={toggleTeachingBubbleVisibleAssessment}
                    />
                  </Stack>
                  <TextField
                    multiline
                    autoAdjustHeight
                    value={assessmentProperties}
                    errorMessage={
                      assessmentProperties && isJsonString(assessmentProperties)
                        ? assessmentProperties.length <= 1000
                          ? ""
                          : "Character limit reached"
                        : "Invalid JSON"
                    }
                    onChange={onChangeAssesmentProperties}
                  />
                </Stack>
              )}

              {isRequiredReader && (
                <Stack tokens={{ childrenGap: 0 }}>
                  <Stack horizontal tokens={{ childrenGap: 0 }}>
                    <Text
                      variant="medium"
                      style={{ marginTop: 4, fontWeight: 600 }}
                    >
                      Method Name
                    </Text>
                    <FontIcon
                      iconName="AsteriskSolid"
                      style={{
                        color: SharedColors.redOrange10,
                        fontSize: 6,
                        marginTop: 4,
                      }}
                    />
                    <IconButton
                      style={{ outline: "none" }}
                      id="method-name-info"
                      iconProps={{
                        iconName: "Info",
                        styles: { root: { fontSize: 16 } },
                      }}
                      aria-label="MethodName Info"
                      onClick={toggleTeachingBubbleVisibleMethodName}
                    />
                  </Stack>
                  <Dropdown
                    selectedKey={methodName}
                    placeholder="Select an option"
                    options={optionsMethodName as unknown as IDropdownOption[]}
                    styles={dropdownStyles}
                    onChange={onChangeMethodName}
                  />
                </Stack>
              )}

              {isRequiredReader &&
                methodName === "EvaluateControlbyReaderUsingLINQAsync" && (
                  <>
                    <Stack tokens={{ childrenGap: 0 }}>
                      <Stack horizontal tokens={{ childrenGap: 0 }}>
                        <Text
                          variant="medium"
                          style={{ marginTop: 4, fontWeight: 600 }}
                        >
                          LINQ Query
                        </Text>
                        <FontIcon
                          iconName="AsteriskSolid"
                          style={{
                            color: SharedColors.redOrange10,
                            fontSize: 6,
                            marginTop: 4,
                          }}
                        />
                        <IconButton
                          style={{ outline: "none" }}
                          id="linq-query-info"
                          iconProps={{
                            iconName: "Info",
                            styles: { root: { fontSize: 16 } },
                          }}
                          aria-label="LINQ query Info"
                          onClick={toggleTeachingBubbleVisibleLINQQuery}
                        />
                      </Stack>
                      <TextField
                        value={JSON.parse(controlSettings).LINQQuery ?? ""}
                        onChange={(_, newValue) => {
                          setControlSettings(
                            JSON.stringify({
                              ...JSON.parse(controlSettings),
                              ApplicableAppServiceKinds: ["app"],
                              LINQQuery: newValue,
                            })
                          );
                        }}
                      />
                    </Stack>
                    <Stack tokens={{ childrenGap: 0 }}>
                      <Stack horizontal tokens={{ childrenGap: 0 }}>
                        <Text
                          variant="medium"
                          style={{ marginTop: 4, fontWeight: 600 }}
                        >
                          Required Field
                        </Text>
                        <FontIcon
                          iconName="AsteriskSolid"
                          style={{
                            color: SharedColors.redOrange10,
                            fontSize: 6,
                            marginTop: 4,
                          }}
                        />
                        <IconButton
                          style={{ outline: "none" }}
                          id="required-field-info"
                          iconProps={{
                            iconName: "Info",
                            styles: { root: { fontSize: 16 } },
                          }}
                          aria-label="Required Field Info"
                          onClick={toggleTeachingBubbleVisibleRequiredField}
                        />
                      </Stack>
                      <TextField
                        value={JSON.parse(controlSettings).RequiredFields ?? ""}
                        placeholder="CustomField2"
                        onChange={(_, newValue) => {
                          setControlSettings(
                            JSON.stringify({
                              ...JSON.parse(controlSettings),
                              ApplicableAppServiceKinds: ["app"],
                              RequiredFields: newValue.trim(),
                            })
                          );
                        }}
                      />
                    </Stack>
                  </>
                )}

              {scanSource === "Policy" && (
                <Stack tokens={{ childrenGap: 15 }}>
                  <Stack horizontal tokens={{ childrenGap: 0 }}>
                    <Text
                      variant="medium"
                      style={{ marginTop: 4, fontWeight: 600 }}
                    >
                      Policy Definition Id and Assignment Id Mapping
                    </Text>
                    <FontIcon
                      iconName="AsteriskSolid"
                      style={{
                        color: SharedColors.redOrange10,
                        fontSize: 6,
                        marginTop: 4,
                      }}
                    />
                    <IconButton
                      style={{ outline: "none" }}
                      id="policyDefinitionIdAssignmentIdMapping-info"
                      iconProps={{
                        iconName: "Info",
                        styles: { root: { fontSize: 16 } },
                      }}
                      aria-label="Policy Definition Id Mapping Info"
                      onClick={toggleTeachingBubbleVisiblePolicyDefinitionMappings}
                    />
                  </Stack>
                  {policyDefinitionMappings.map((mapping, index) => (
                    <Stack
                      key={mapping.id}
                      horizontal
                      tokens={{ childrenGap: 10 }}
                      verticalAlign="center"
                    >
                      <TextField
                        label={`Policy Definition Id ${index + 1}`}
                        value={mapping.policyDefinitionId}
                        onChange={(e, newValue) =>
                            updatePolicyDefinitionMapping(
                            mapping.id,
                            "policyDefinitionId",
                            newValue || ""
                          )
                        }
                        style={{ width: 250 }}
                      />
                      <TextField
                        label={`Assignment Id ${index + 1}`}
                        value={mapping.assignmentId}
                        onChange={(e, newValue) =>
                            updatePolicyDefinitionMapping(
                            mapping.id,
                            "assignmentId",
                            newValue || ""
                          )
                        }
                        style={{ width: 250 }}
                      />
                      {policyDefinitionMappings.length !== 1 && (
                        <IconButton
                          iconProps={{ iconName: "Delete" }}
                          onClick={() => deleteMapping(mapping.id)}
                          styles={{ root: { marginTop: 29 } }}
                          ariaLabel="Delete Mapping"
                          title="Delete Mapping"
                        />
                      )}
                      {index === policyDefinitionMappings.length - 1 && (
                        <IconButton
                          iconProps={{ iconName: "Add" }}
                          onClick={() => addNewMapping()}
                          styles={{ root: { marginTop: 29 } }}
                          ariaLabel="Add Mapping"
                          title="Add Mapping"
                        />
                      )}
                    </Stack>
                  ))}
                </Stack>
              )}

              {scanSource === "Policy" && (
                <Stack tokens={{ childrenGap: 0 }}>
                  <Stack horizontal tokens={{ childrenGap: 0 }}>
                    <Text
                      variant="medium"
                      style={{ marginTop: 4, fontWeight: 600 }}
                    >
                      Custom Policy Properties
                    </Text>
                    <IconButton
                      style={{ outline: "none" }}
                      id="customPolicyProperties-info"
                      iconProps={{
                        iconName: "Info",
                        styles: { root: { fontSize: 16 } },
                      }}
                      aria-label="Custom Policy Properties Info"
                      onClick={toggleTeachingBubbleVisiblePolicyProperties}
                    />
                  </Stack>
                  <TextField
                    multiline
                    autoAdjustHeight
                    value={customPolicyProperties}
                    errorMessage={
                      customPolicyProperties &&
                      isJsonString(customPolicyProperties)
                        ? ""
                        : "Invalid JSON"
                    }
                    onChange={onChangeCustomPolicyProperties}
                  />
                </Stack>
              )}

              <Stack tokens={{ childrenGap: 0 }}>
                <Stack horizontal tokens={{ childrenGap: 0 }}>
                  <Text
                    variant="medium"
                    style={{ marginTop: 4, fontWeight: 600 }}
                  >
                    Category
                  </Text>
                  <FontIcon
                    iconName="AsteriskSolid"
                    style={{
                      color: SharedColors.redOrange10,
                      fontSize: 6,
                      marginTop: 4,
                    }}
                  />
                  <IconButton
                    style={{ outline: "none" }}
                    id="category-info"
                    iconProps={{
                      iconName: "Info",
                      styles: { root: { fontSize: 16 } },
                    }}
                    aria-label="Category Info"
                    onClick={toggleTeachingBubbleVisibleCategory}
                  />
                </Stack>
                <ComboBox
                  placeholder="Select an option or enter a value"
                  options={categoryOptions}
                  style={{ width: "100%" }}
                  selectedKey={category}
                  allowFreeform
                  onChange={onChangeCategory}
                />
              </Stack>

              <Stack tokens={{ childrenGap: 0 }}>
                <Stack horizontal tokens={{ childrenGap: 0 }}>
                  <Text
                    variant="medium"
                    style={{ marginTop: 4, fontWeight: 600 }}
                  >
                    Enabled
                  </Text>
                  <IconButton
                    style={{ outline: "none" }}
                    id="enabled-info"
                    iconProps={{
                      iconName: "Info",
                      styles: { root: { fontSize: 16 } },
                    }}
                    aria-label="Enabled Info"
                    onClick={toggleTeachingBubbleVisibleEnabled}
                  />
                </Stack>
                <Toggle
                  defaultChecked={true}
                  onText="Yes"
                  offText="No"
                  onChange={onEnable}
                />
              </Stack>

              <CollapsibleSection
                key={1}
                defaultCollapsed={true}
                collapsed={!showOptional}
                title={{
                  text: "Optional Settings",
                  "aria-label": "Collapse subscription",
                  chevron: {
                    color: theme.palette.themePrimary,
                    style: { outline: "none" },
                  },
                  styles: {
                    text: theme.fonts.xLarge,
                    root: {
                      marginTop: 10,
                      color: theme.palette.themeDarkAlt,
                      outline: "none !important",
                      selectors: {
                        ":hover": {
                          backgroundColor: "inherit",
                        },
                      },
                    },
                  },
                  onClick: () => {
                    setShowOptional(!showOptional);
                  },
                }}
              ></CollapsibleSection>

              {showOptional && (
                <Stack tokens={{ childrenGap: 10 }}>
                  <Stack tokens={{ childrenGap: 0 }}>
                    <Stack horizontal tokens={{ childrenGap: 0 }}>
                      <Text
                        variant="medium"
                        style={{ marginTop: 4, fontWeight: 600 }}
                      >
                        Automated
                      </Text>
                      <IconButton
                        style={{ outline: "none" }}
                        id="automated-info"
                        iconProps={{
                          iconName: "Info",
                          styles: { root: { fontSize: 16 } },
                        }}
                        aria-label="Automated Info"
                        onClick={toggleTeachingBubbleVisibleAutomated}
                      />
                    </Stack>
                    <Toggle
                      defaultChecked
                      onText="Yes"
                      offText="No"
                      disabled
                      onChange={onAutomated}
                    />
                  </Stack>

                  <Stack tokens={{ childrenGap: 0 }}>
                    <Stack horizontal tokens={{ childrenGap: 0 }}>
                      <Text
                        variant="medium"
                        style={{ marginTop: 4, fontWeight: 600 }}
                      >
                        Description
                      </Text>
                      <IconButton
                        style={{ outline: "none" }}
                        id="description-info"
                        iconProps={{
                          iconName: "Info",
                          styles: { root: { fontSize: 16 } },
                        }}
                        aria-label="Description Info"
                        onClick={toggleTeachingBubbleVisibleDescription}
                      />
                    </Stack>
                    <TextField
                      multiline
                      autoAdjustHeight
                      value={description}
                      errorMessage={
                        description
                          ? description.length <= 1000
                            ? ""
                            : "Character limit reached"
                          : ""
                      }
                      onChange={onChangeDescription}
                    />
                  </Stack>

                  <Stack tokens={{ childrenGap: 0 }}>
                    <Stack horizontal tokens={{ childrenGap: 0 }}>
                      <Text
                        variant="medium"
                        style={{ marginTop: 4, fontWeight: 600 }}
                      >
                        Tags
                      </Text>
                      <IconButton
                        style={{ outline: "none" }}
                        id="tags-info"
                        iconProps={{
                          iconName: "Info",
                          styles: { root: { fontSize: 16 } },
                        }}
                        aria-label="Tags Info"
                        onClick={toggleTeachingBubbleVisibleTags}
                      />
                    </Stack>
                    <ComboBox
                      options={optionsTags}
                      style={{ width: "100%" }}
                      onChange={onChangeTags}
                      multiSelect
                    />
                  </Stack>

                  <Stack tokens={{ childrenGap: 0 }}>
                    <Stack horizontal tokens={{ childrenGap: 0 }}>
                      <Text
                        variant="medium"
                        style={{ marginTop: 4, fontWeight: 600 }}
                      >
                        Custom Tags
                      </Text>
                      <IconButton
                        style={{ outline: "none" }}
                        id="customTags-info"
                        iconProps={{
                          iconName: "Info",
                          styles: { root: { fontSize: 16 } },
                        }}
                        aria-label="Custom Tags Info"
                        onClick={toggleTeachingBubbleVisibleCustomTags}
                      />
                    </Stack>
                    <ComboBox
                      selectedKey={customTags}
                      options={customTagsOptions}
                      multiSelect
                      autoComplete="on"
                      allowFreeform={true}
                      onChange={onChangeCustomTags}
                    />
                  </Stack>

                  <Stack tokens={{ childrenGap: 0 }}>
                    <Stack horizontal tokens={{ childrenGap: 0 }}>
                      <Text
                        variant="medium"
                        style={{ marginTop: 4, fontWeight: 600 }}
                      >
                        Control Severity
                      </Text>
                      <IconButton
                        style={{ outline: "none" }}
                        id="severity-info"
                        iconProps={{
                          iconName: "Info",
                          styles: { root: { fontSize: 16 } },
                        }}
                        aria-label="Severity Info"
                        onClick={toggleTeachingBubbleVisibleSeverity}
                      />
                    </Stack>
                    <Dropdown
                      selectedKey={severity === "" ? undefined : severity}
                      placeholder="Select an option"
                      options={optionsSeverity}
                      styles={dropdownStyles}
                      onChange={onChangeSeverity}
                    />
                  </Stack>

                  <Stack tokens={{ childrenGap: 0 }}>
                    <Stack horizontal tokens={{ childrenGap: 0 }}>
                      <Text
                        variant="medium"
                        style={{ marginTop: 4, fontWeight: 600 }}
                      >
                        Control Requirements
                      </Text>
                      <IconButton
                        style={{ outline: "none" }}
                        id="requirements-info"
                        iconProps={{
                          iconName: "Info",
                          styles: { root: { fontSize: 16 } },
                        }}
                        aria-label="Requirement Info"
                        onClick={toggleTeachingBubbleVisibleRequirements}
                      />
                    </Stack>
                    <TextField
                      multiline
                      autoAdjustHeight
                      value={controlRequirements}
                      errorMessage={
                        controlRequirements
                          ? controlRequirements.length <= 1000
                            ? ""
                            : "Character limit reached"
                          : ""
                      }
                      onChange={onChangeControlRequirements}
                    />
                  </Stack>

                  <Stack tokens={{ childrenGap: 0 }}>
                    <Stack horizontal tokens={{ childrenGap: 0 }}>
                      <Text
                        variant="medium"
                        style={{ marginTop: 4, fontWeight: 600 }}
                      >
                        Rationale
                      </Text>
                      <IconButton
                        style={{ outline: "none" }}
                        id="rationale-info"
                        iconProps={{
                          iconName: "Info",
                          styles: { root: { fontSize: 16 } },
                        }}
                        aria-label="Rationale Info"
                        onClick={toggleTeachingBubbleVisibleRationale}
                      />
                    </Stack>
                    <TextField
                      multiline
                      autoAdjustHeight
                      value={rationale}
                      errorMessage={
                        rationale
                          ? rationale.length <= 1000
                            ? ""
                            : "Character limit reached"
                          : ""
                      }
                      onChange={onChangeRationale}
                    />
                  </Stack>

                  <Stack tokens={{ childrenGap: 0 }}>
                    <Stack horizontal tokens={{ childrenGap: 0 }}>
                      <Text
                        variant="medium"
                        style={{ marginTop: 4, fontWeight: 600 }}
                      >
                        Recommendations
                      </Text>
                      <IconButton
                        style={{ outline: "none" }}
                        id="recommendation-info"
                        iconProps={{
                          iconName: "Info",
                          styles: { root: { fontSize: 16 } },
                        }}
                        aria-label="Recommendation Info"
                        onClick={toggleTeachingBubbleVisibleRecommendation}
                      />
                    </Stack>
                    <TextField
                      multiline
                      autoAdjustHeight
                      value={recommendation}
                      errorMessage={
                        recommendation
                          ? recommendation.length <= 1000
                            ? ""
                            : "Character limit reached"
                          : ""
                      }
                      onChange={onChangeRecommendation}
                    />
                  </Stack>

                  {!isRequiredReader && (
                    <Stack tokens={{ childrenGap: 0 }}>
                      <Stack horizontal tokens={{ childrenGap: 0 }}>
                        <Text
                          variant="medium"
                          style={{ marginTop: 4, fontWeight: 600 }}
                        >
                          Control Settings
                        </Text>
                        <IconButton
                          style={{ outline: "none" }}
                          id="optional-control-settings-info"
                          iconProps={{
                            iconName: "Info",
                            styles: { root: { fontSize: 16 } },
                          }}
                          aria-label="ControlSettings Info"
                          onClick={toggleTeachingBubbleVisibleSettings}
                        />
                      </Stack>
                      <TextField
                        multiline
                        autoAdjustHeight
                        value={controlSettings}
                        errorMessage={
                          controlSettings && isJsonString(controlSettings)
                            ? ""
                            : "Invalid JSON"
                        }
                        onChange={onChangeControlSettings}
                      />
                    </Stack>
                  )}

                  <Stack tokens={{ childrenGap: 0 }}>
                    <Stack horizontal tokens={{ childrenGap: 0 }}>
                      <Text
                        variant="medium"
                        style={{ marginTop: 4, fontWeight: 600 }}
                      >
                        Comments
                      </Text>
                      <IconButton
                        style={{ outline: "none" }}
                        id="comments-info"
                        iconProps={{
                          iconName: "Info",
                          styles: { root: { fontSize: 16 } },
                        }}
                        aria-label="Comments Info"
                        onClick={toggleTeachingBubbleVisibleComments}
                      />
                    </Stack>
                    <TextField
                      multiline
                      autoAdjustHeight
                      value={comment}
                      errorMessage={
                        comment
                          ? comment.length <= 1000
                            ? ""
                            : "Character limit reached"
                          : ""
                      }
                      onChange={onChangeComment}
                    />
                  </Stack>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </Panel>
    </div>
  );
};
