import React from 'react';
import { ConstrainMode, DetailsList, Text, MarqueeSelection, SelectionMode, Selection,
    Stack, IColumn, Sticky, StickyPositionType, ProgressIndicator, getTheme, DefaultButton, TextField } from '@fluentui/react';
import { tableStyles } from '../Styles/Table/Common';
import { onRenderDetailsHeaderControlEditor } from '../Helper/Table/renderHeaders';
import { IAdminTableItem, IAdminTableState, IFormAdmin } from '../Interface/IAdminTable';
import { authProvider } from '../../authProvider';
import { AdminContext } from '../context/ControlEditor';
import { classNames } from '../Styles/Table/Group';
import { ExportButton } from './Export';
import { toast } from 'react-toastify';

const theme = getTheme();

const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;

export class TableControlEditor extends React.Component<{}, IAdminTableState> {
    private _selection: Selection;
    private _allItems: IAdminTableItem[];
    private RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;

    static contextType = AdminContext;

    constructor(props?) {
        super(props);
        this._allItems = [];

        const columns: IColumn[] = [
            {
                key: 'controlID',
                name: 'Control ID',
                fieldName: 'ControlId',
                minWidth: 250,
                //isSortedDescending: false,
                //isSorted: true,
                isPadded: false,
                maxWidth: 300,
                isResizable: true,
            },
            {
                key: 'displayName',
                name: 'Display Name',
                fieldName: 'DisplayName',
                minWidth: 250,
                //isSortedDescending: false,
                //isSorted: true,
                isPadded: false,
                maxWidth: 450,
                isResizable: true,
            },
            {
                key: 'category',
                name: 'Category',
                fieldName: 'Category',
                minWidth: 250,
                //isSortedDescending: false,
                //isSorted: true,
                isPadded: false,
                maxWidth: 450,
                isResizable: true,
            },
            {
                key: 'enable',
                name: 'Enabled',
                fieldName: 'Enabled',
                minWidth: 100,
                //isSortedDescending: false,
                //isSorted: true,
                isPadded: false,
                maxWidth: 150,
                isResizable: true,
            },
            {
                key: 'automated',
                name: 'Ext Scanned',
                fieldName: 'Automated',
                minWidth: 100,
                isPadded: false,
                maxWidth: 150,
                isResizable: true,
            }
        ]

        this._selection = new Selection({
            onSelectionChanged: () => {
                if(this._selection.getSelectedCount() > 0) {
                    var selectedItems = this._selection.getSelection();
                    if(this._selection.getSelectedCount() === 1){
                        this.context.setSelectedRow(selectedItems[0]);
                        this.context.toggleControlPanel();
                        this.context.setBulkEditRows([]);
                    }
                    else{
                        this.context.setBulkEditRows(selectedItems);
                        this.context.setSelectedRow([]);
                    }
                }
                else{
                    this.context.setBulkEditRows([]);
                    this.context.setSelectedRow([]);
                }
            }
        });

        const formConf: IFormAdmin = {
            resource: [],
            control: [],
            scanSource: [],
        }
        this.state = {
            items: [],
            columns: columns,
            formConf: formConf,
            loaded: false,
            forceFetch: false,
            infoTable: true,
            searchText: "",
        }
    }

    private _processItemData(dataString : string) {
        var data = JSON.parse(dataString);
        var new_items:IAdminTableItem[] = [];
        for (var i = 0; i < data.length; i++) {
            new_items = new_items.concat({
                Id: data[i].id,
                ControlId: data[i].controlId,
                FeatureName: data[i].featureName,
                Category: data[i].category,
                DisplayName: data[i].displayName,
                ControlRequirements: data[i].controlRequirements,
                ControlSeverity: data[i].controlSeverity,
                ControlScanSource: data[i].controlScanSource,
                OriginalScanSource: data[i].originalScanSource,
                Description: data[i].description,
                Tags: data[i].tags,
                CustomTags: data[i].customTags,
                Fields: data[i].fields,
                Rationale: data[i].rationale,
                Recommendation: data[i].recommendation,
                Enabled: data[i].enabled,
                Automated: data[i].automated == "No" ? true : false
            })
        }
        this.setState({
            items: new_items,
        });
        this._allItems = new_items;
    }

    private _compareContextWithState() {
        if(JSON.stringify(this.context.resource) === JSON.stringify(this.state.formConf.resource) && 
            JSON.stringify(this.context.control) === JSON.stringify(this.state.formConf.control) && JSON.stringify(this.context.scanSource) === JSON.stringify(this.state.formConf.scanSource)) {
            return 0;
        }
        return 1;
    }

    private _checkContextIsEmpty() {
        if(this.context.resource && this.context.control && this.context.scanSource) {
            return 1;
        }
        return 0;
    }

    public _setStateWithContext() {
        var form : IFormAdmin = {
            resource: this.context.resource,
            control: this.context.control,
            scanSource: this.context.scanSource
        }

        this.setState({
            formConf : form
        });
    }

    public componentDidUpdate() {
        if((this._compareContextWithState() && this._checkContextIsEmpty()) || this.state.forceFetch) {
            this.setState({
                forceFetch:false
            })
            this.fetchItems();
            this._setStateWithContext();
        }
    }

    private _onSearch(text: string){
        text = text.toLowerCase();
        var new_Items = this._allItems.filter(i => 
            i.ControlId?.toLowerCase().includes(text) ||
            i.DisplayName?.toLowerCase().includes(text) ||
            i.ControlRequirements?.toLowerCase().includes(text) ||
            i.Description?.toLowerCase().includes(text) ||
            i.Category?.toLowerCase().includes(text) || 
            i.Rationale?.toLowerCase().includes(text) || 
            i.Recommendation?.toLowerCase().includes(text) || 
            i.Tags?.map(function(x){ return x.toLowerCase();}).includes(text) ||
            i.FeatureName?.toLowerCase().includes(text) || 
            i.Fields?.join(" ").toLowerCase().includes(text) ||
            i.CustomTags?.map(function(x){ return x.toLowerCase();}).includes(text)
        );
        var filteredItems = text ? new_Items : this._allItems
        this.setState({
          items: filteredItems,
        });
        var idsDistinct = filteredItems.map(t => t.Id);
        this.context.setControlFilteredDetails(idsDistinct);
    }

    private _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({
           searchText : text
        })
        this._onSearch(text);
    };

    private fetchItems(){
        const CallAPI = async () => {
            this.setState({
                loaded: false
            })
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            let body = {
                ResourceType: this.context.resource,
                Ids: this.context.control,
                ScanSource: this.context.scanSource
            }
            fetch((ScannerAPI + '/controleditor/controldetails/'), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(body)
            }).then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        this._processItemData(JSON.stringify(data));
                        this.setState({
                            loaded: true
                        })
                    });
                }
                else {
                    response.text().then(errorMsg => {
                        toast(errorMsg.slice(0, 500));
                    });
                }
            });  
        }
        CallAPI();
    }

    public render() {
        return this.context.tableVisible ? 
            <div>
                {!this.state.loaded && <ProgressIndicator barHeight={4} label="" description=""/>}
                {this.context.loadingTable && <ProgressIndicator barHeight={4} label="" description=""/>}
                <div style = {tableStyles}>
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack horizontal  style={{marginLeft: 30,marginTop: 24}} tokens={{childrenGap:20}}>
                        {/* {
                            this.state.infoTable && 
                            <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                                onDismiss={()=>{
                                    this.setState({
                                        infoTable: false
                                    });
                                }}
                                dismissButtonAriaLabel="Close"
                                className={classNames.sticky}
                            >
                                <Text style={{fontWeight:600}}>Did you know?</Text>
                                <ul style={{marginLeft:-25,marginTop:5}}>
                                    <li>
                                        You can click on individual control to open edit panel.
                                    </li>
                                    <li>
                                        Update wave tag in CustomTags field to over-ride preset values.
                                    </li>
                                    <li>
                                        You can override TenantBaseline tag by adding ExcludedControl tag in CustomTags field.
                                    </li>
                                </ul>
                            </MessageBar> 
                        } */}
                        <Text variant="large">
                            <b style={{fontWeight:600}}>{this.state.items.length}</b> controls filtered
                        </Text>
                        </Stack>
                        <Sticky stickyPosition={StickyPositionType.Header} stickyClassName={classNames.pane}>
                            <Stack >
                                <Stack horizontalAlign='end' horizontal tokens={{childrenGap:20}} style={{marginRight:24,   marginTop:24}}>
                                    <TextField 
                                        placeholder="Search on any field (Tags, Control ID etc)" 
                                        value = {this.state.searchText}
                                        onChange={this._onChangeText} 
                                        iconProps={{iconName:'Search'}} 
                                        style={{minWidth: 320}}
                                    />
                                    <DefaultButton 
                                        text={"Refresh"}
                                        iconProps={{iconName:'Refresh'}}
                                        style={{outline:"none", boxShadow: theme.effects.elevation4}}
                                        onClick={()=>{
                                            this.setState({
                                                forceFetch: true,
                                                loaded: false,
                                                searchText: ""
                                            })
                                        }}
                                    />
                                    <ExportButton />
                                </Stack>
                            </Stack>
                        </Sticky>
                    </Stack>
                        
                    <Stack style={{marginLeft:30,marginRight:30}}>
                        <MarqueeSelection selection={this._selection} isEnabled={false} role="grid">
                            <DetailsList
                                items={this.state.items}
                                styles={{root:{paddingBottom:20}}}
                                selectionPreservedOnEmptyClick={true}
                                checkboxVisibility={1}
                                selectionMode={this.RuntimeConfigurationExtended.controlEditorFeatureConfiguration.isBulkEditEnabled === true ? SelectionMode.multiple : SelectionMode.single}
                                constrainMode={ConstrainMode.unconstrained}
                                enableUpdateAnimations={true}
                                isHeaderVisible={true}
                                selection={this._selection}
                                columns={this.state.columns}
                                ariaLabelForSelectAllCheckbox={"Selecting all controls is viable"}
                                checkButtonAriaLabel={"Select this control"}
                                onRenderDetailsHeader={onRenderDetailsHeaderControlEditor}
                            />
                        </MarqueeSelection>
                    </Stack>
                </div>
            </div> : <div></div>
    }
}