import React, { useContext, useMemo } from 'react';
import { IButtonProps } from '@fluentui/react/lib/Button';
import { ITeachingBubbleStyles, Link, NeutralColors, Stack, TeachingBubble } from '@fluentui/react';
import { HelpContext } from '../context/HelpContextControlEditor';

const BASE_LINQ_QUERY = "var encryptionEnabled = (bool?)customFieldData[\"EndtoEndEncryption\"]; var isControlApplicable = (resource.Kind != null && resource.Kind.ToLower().StartsWith(\"app\")); return string.IsNullOrWhiteSpace(resource.Kind) ? new { VerificationResult = \"Error\", StatusReason = \"App Service Kind is null or empty.\" } : !isControlApplicable ? new { VerificationResult = \"NotApplicable\", StatusReason = $\"App Service Kind {resource.Kind} is excluded from the evaluation.\" } : encryptionEnabled.HasValue && encryptionEnabled.Value ? new { VerificationResult = \"Passed\", StatusReason = \"End-To-End TLS encryption is enabled for the App Service\" } : new { VerificationResult = \"Failed\", StatusReason = \"End-To-End TLS encryption is not enabled for the App Service\" };"

export const HelpTable: React.FunctionComponent = () => {

    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;
    
    const {teachingBubbleVisiblePanel, toggleTeachingBubbleVisiblePanel} = useContext(HelpContext);
    const {teachingBubbleVisibleFeature, toggleTeachingBubbleVisibleFeature} = useContext(HelpContext);
    const {teachingBubbleVisibleSecurityDomain, toggleTeachingBubbleVisibleSecurityDomain} = useContext(HelpContext);
    const {teachingBubbleVisibleControlID, toggleTeachingBubbleVisibleControlID} = useContext(HelpContext);
    const {teachingBubbleVisibleScanSource, toggleTeachingBubbleVisibleScanSource} = useContext(HelpContext);
    const {teachingBubbleVisibleName, toggleTeachingBubbleVisibleName} = useContext(HelpContext);
    const {teachingBubbleVisibleDescription, toggleTeachingBubbleVisibleDescription} = useContext(HelpContext);
    const {teachingBubbleVisibleTags, toggleTeachingBubbleVisibleTags} = useContext(HelpContext);
    const {teachingBubbleVisibleSeverity, toggleTeachingBubbleVisibleSeverity} = useContext(HelpContext);
    const {teachingBubbleVisibleCategory, toggleTeachingBubbleVisibleCategory} = useContext(HelpContext);
    const {teachingBubbleVisibleAutomated, toggleTeachingBubbleVisibleAutomated} = useContext(HelpContext);
    const {teachingBubbleVisibleRequirements, toggleTeachingBubbleVisibleRequirements} = useContext(HelpContext);
    const {teachingBubbleVisibleAssessment, toggleTeachingBubbleVisibleAssessment} = useContext(HelpContext);
    const {teachingBubbleVisibleRationale, toggleTeachingBubbleVisibleRationale} = useContext(HelpContext);
    const {teachingBubbleVisibleRecommendation, toggleTeachingBubbleVisibleRecommendation} = useContext(HelpContext);
    const {teachingBubbleVisibleCustomTags, toggleTeachingBubbleVisibleCustomTags} = useContext(HelpContext);
    const {teachingBubbleVisibleEnabled, toggleTeachingBubbleVisibleEnabled} = useContext(HelpContext);
    const {teachingBubbleVisibleSettings, toggleTeachingBubbleVisibleSettings} = useContext(HelpContext);
    const {teachingBubbleVisibleComments, toggleTeachingBubbleVisibleComments} = useContext(HelpContext);
    const {teachingBubbleVisiblePolicyDefinitionMappings, toggleTeachingBubbleVisiblePolicyDefinitionMappings} = useContext(HelpContext);
    const {teachingBubbleVisiblePolicyProperties, toggleTeachingBubbleVisiblePolicyProperties} = useContext(HelpContext);
    const {teachingBubbleVisibleMethodName, toggleTeachingBubbleVisibleMethodName} = useContext(HelpContext);
    const { teachingBubbleVisibleLINQQuery, toggleTeachingBubbleVisibleLINQQuery, teachingBubbleVisibleRequiredField, toggleTeachingBubbleVisibleRequiredField } = useContext(HelpContext);


    const copyLINQQueryButtonProps: IButtonProps = useMemo(
        () => ({
          children: "Copy LINQ Query",
          onClick: () => {
            navigator.clipboard.writeText(BASE_LINQ_QUERY).then(function() {
                toggleTeachingBubbleVisibleLINQQuery()
            });
          },
        }),
        [toggleTeachingBubbleVisibleLINQQuery],
      );

  const teachingStyles : Partial<ITeachingBubbleStyles> = {
      root: {
          transition:'0.5s'
      }
  }

  return (
    <>
      <Stack>
        {teachingBubbleVisibleLINQQuery && (
            <TeachingBubble
                target="#linq-query-info"
                headline="Enter LINQ query"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                onDismiss={toggleTeachingBubbleVisibleLINQQuery}
                closeButtonAriaLabel='Close Info'
                primaryButtonProps={copyLINQQueryButtonProps}
            >
                Example: 
                <pre style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    color: "whitesmoke"
                }}>
                    <code>
                        {BASE_LINQ_QUERY}
                    </code>
                </pre>
            </TeachingBubble>
        )}

        {teachingBubbleVisibleRequiredField && (
            <TeachingBubble
                target="#required-field-info"
                headline="Enter Required Field"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                onDismiss={toggleTeachingBubbleVisibleRequiredField}
                closeButtonAriaLabel='Close Info'
            >
                Example: CustomField2
            </TeachingBubble>
        )}

        {teachingBubbleVisibleMethodName && (
            <TeachingBubble
                target="#method-name-info"
                headline="Select the reader method name"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                onDismiss={toggleTeachingBubbleVisibleMethodName}
                closeButtonAriaLabel='Close Info'
            >
                The name of the reader method that would be used to read the control.
            </TeachingBubble>
        )}

        {teachingBubbleVisiblePanel && (
            <TeachingBubble
                target="#panel-info"
                headline="Welcome to the Add new Control Panel"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // footerContent="1 of 19"
                // primaryButtonProps={panelNext}
                onDismiss={toggleTeachingBubbleVisiblePanel}
                closeButtonAriaLabel='Close Info'
            >
                This tour will guide you on how to use this tool.
            </TeachingBubble>
        )} 

        {teachingBubbleVisibleFeature && (
            <TeachingBubble
                target="#feature-info"
                headline="Select your resource type"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // footerContent="2 of 19"
                // primaryButtonProps={featureNext}
                // secondaryButtonProps={featurePrevious}
                onDismiss={toggleTeachingBubbleVisibleFeature}
                closeButtonAriaLabel='Close Info'
            >
                	The resource type for the new control from the dropdown.

            </TeachingBubble>
        )}

        {teachingBubbleVisibleSecurityDomain && (
            <TeachingBubble
                target="#domain-info"
                headline="Select your security domain"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // footerContent="3 of 19"
                // primaryButtonProps={domainNext}
                // secondaryButtonProps={domainPrevious}
                onDismiss={toggleTeachingBubbleVisibleSecurityDomain}
                closeButtonAriaLabel='Close Info'
            >
                The domain of security in which the control lies. You can either select or enter a new one.
            </TeachingBubble>
        )}  

        {teachingBubbleVisibleControlID && (
            <TeachingBubble
                target="#controlId-info"
                headline="Enter ControlID"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                // primaryButtonProps={controlIdNext}
                hasCloseButton={true}
                // secondaryButtonProps={controlIdPrevious}
                // footerContent="4 of 19"
                onDismiss={toggleTeachingBubbleVisibleControlID}
                closeButtonAriaLabel='Close Info'
            >
                	The ID for the control that would show up in the scan. (The first part of the control ID would be pre-populated based on the feature and security domain).
            </TeachingBubble>
        )} 

        {teachingBubbleVisibleScanSource && (
            <TeachingBubble
                target="#scanSource-info"
                headline="Select the Scan Source"
                hasCondensedHeadline={true}
                hasCloseButton={true}
                styles={teachingStyles}
                // primaryButtonProps={scanSourceNext}
                // secondaryButtonProps={scanSourcePrevious}
                // footerContent="5 of 19"
                onDismiss={toggleTeachingBubbleVisibleScanSource}
                closeButtonAriaLabel='Close Info'
            >
                Defines whether the control would be evaluated on the basis of an assessment from Azure Security Centre or from a policy in Azure Policy.
            </TeachingBubble>
        )}     

        {teachingBubbleVisibleName && (
            <TeachingBubble
                target="#displayName-info"
                headline="Enter the display name"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={nameNext}
                // secondaryButtonProps={namePrevious}
                // footerContent="6 of 19"
                onDismiss={toggleTeachingBubbleVisibleName}
                closeButtonAriaLabel='Close Info'
            >
                The display name that is populated for the control in every menu. If you are using MDC assessment, please select an option that is consistent with the feature.
               
            </TeachingBubble>
        )} 

        {teachingBubbleVisibleDescription && (
            <TeachingBubble
                target="#description-info"
                headline="Enter the control description"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={descriptionNext}
                // secondaryButtonProps={descriptionPrevious}
                // footerContent="7 of 19"
                onDismiss={toggleTeachingBubbleVisibleDescription}
                closeButtonAriaLabel='Close Info'
            >
                A basic description on what the control is about.
               
            </TeachingBubble>
        )} 

        {teachingBubbleVisibleTags && (
            <TeachingBubble
                target="#tags-info"
                headline="Select the required tags"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={tagsNext}
                // secondaryButtonProps={tagsPrevious}
                // footerContent="8 of 19"
                onDismiss={toggleTeachingBubbleVisibleTags}
                closeButtonAriaLabel='Close Info'
            >
                Labels that denote the control being of a specific type or belonging to a specific domain.
               
            </TeachingBubble>
        )} 

        {teachingBubbleVisibleSeverity && (
            <TeachingBubble
                target="#severity-info"
                headline="Select the control severity"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={severityNext}
                // secondaryButtonProps={severityPrevious}
                // footerContent="9 of 19"
                onDismiss={toggleTeachingBubbleVisibleSeverity}
                closeButtonAriaLabel='Close Info'
            >
                The severity of the control.
                High: Should be remediated as soon as possible.
                Medium: Should be considered for remediation.
                Low: Remediation should be prioritized after high and medium.
            </TeachingBubble>
        )} 

        {teachingBubbleVisibleCategory && (
            <TeachingBubble
                target="#category-info"
                headline="Select the category"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={categoryNext}
                // secondaryButtonProps={categoryPrevious}
                // footerContent="10 of 19"
                onDismiss={toggleTeachingBubbleVisibleCategory}
                closeButtonAriaLabel='Close Info'
            >
                The generic security specification of the control. Select one from the dropdown or enter a new value.
               
            </TeachingBubble>
        )} 

        {teachingBubbleVisibleAutomated && (
            <TeachingBubble
                target="#automated-info"
                headline="Toggle between Manual and Automated"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={automatedNext}
                // secondaryButtonProps={automatedPrevious}
                // footerContent="11 of 19"
                onDismiss={toggleTeachingBubbleVisibleAutomated}
                closeButtonAriaLabel='Close Info'
            >
                Whether the control is manual or automated. (A tag specific to this feature would be added to tags on addition)
               
            </TeachingBubble>
        )} 

        {teachingBubbleVisibleRequirements && (
            <TeachingBubble
                target="#requirements-info"
                headline="Enter the control requirements"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={requirementsNext}
                // secondaryButtonProps={requirementsPrevious}
                // footerContent="12 of 19"
                onDismiss={toggleTeachingBubbleVisibleRequirements}
                closeButtonAriaLabel='Close Info'
            >
                Prerequisites for the control.
               
            </TeachingBubble>
        )} 

        {teachingBubbleVisibleAssessment && (
            <TeachingBubble
                target="#assessment-info"
                headline="Enter the assessment properties"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={assessmentNext}
                // secondaryButtonProps={assessmentPrevious}
                // footerContent="13 of 19"
                onDismiss={toggleTeachingBubbleVisibleAssessment}
                closeButtonAriaLabel='Close Info'
            >
                Assessment properties containing MDC assessment details.
            </TeachingBubble>
        )} 

        {teachingBubbleVisibleRationale && (
            <TeachingBubble
                target="#rationale-info"
                headline="Enter the rationale"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={rationaleNext}
                // secondaryButtonProps={rationalePrevious}
                // footerContent="14 of 19"
                onDismiss={toggleTeachingBubbleVisibleRationale}
                closeButtonAriaLabel='Close Info'
            >
                Reason for implementing the control.
               
            </TeachingBubble>
        )} 

        {teachingBubbleVisibleRecommendation && (
            <TeachingBubble
                target="#recommendation-info"
                headline="Enter the recommendations"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={recommendationNext}
                // secondaryButtonProps={recommendationPrevious}
                // footerContent="15 of 19"
                onDismiss={toggleTeachingBubbleVisibleRecommendation}
                closeButtonAriaLabel='Close Info'
            >
                Recommendations on what steps need to be taken when the control passes or fails.
               
            </TeachingBubble>
        )}

        {teachingBubbleVisibleCustomTags && (
            <TeachingBubble
                target="#customTags-info"
                headline="Add custom tags"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={customTagsNext}
                // secondaryButtonProps={customTagsPrevious}
                // footerContent="16 of 19"
                onDismiss={toggleTeachingBubbleVisibleCustomTags}
                closeButtonAriaLabel='Close Info'
            >
                Tags defined by the user for filtering and referring in the future.
               
            </TeachingBubble>
        )}

        {teachingBubbleVisibleEnabled && (
            <TeachingBubble
                target="#enabled-info"
                headline="Toggle between enabled or disabled"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={enabledNext}
                // secondaryButtonProps={enabledPrevious}
                // footerContent="17 of 19"
                onDismiss={toggleTeachingBubbleVisibleEnabled}
                closeButtonAriaLabel='Close Info'
            >
                Whether the control is enabled for scan or not.
               
            </TeachingBubble>
        )}

        {teachingBubbleVisiblePolicyDefinitionMappings && (
            <TeachingBubble
                target="#policyDefinitionIdAssignmentIdMapping-info"
                headline="Enter Policy Definition Id and Assigment Id mappings"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={commentsNext}
                // secondaryButtonProps={commentsPrevious}
                // footerContent="19 of 19"
                onDismiss={toggleTeachingBubbleVisiblePolicyDefinitionMappings}
                closeButtonAriaLabel='Close Info'
            >
                Please enter valid Definition Id and Assignemnt Id mapping(s) from Azure Policy.
                Please select valid Definition Ids consistent with the feature selected.
                Click <Link href={RuntimeConfigurationExtended.azureAPI + "/#blade/Microsoft_Azure_Policy/PolicyMenuBlade/Definitions"} target="_blank" style={{color: NeutralColors.white}}>here</Link> to view eligible Definition Ids.
                Please select a valid Assignment Id consistent with the feature selected.
                Click <Link href={RuntimeConfigurationExtended.azureAPI+"/#blade/Microsoft_Azure_Policy/PolicyMenuBlade/Assignments"} target="_blank" style={{color: NeutralColors.white}}>here</Link> to view eligible Assignment Ids.
                Please enter atleast one valid Policy Definition Id to add the new policy based control. Assignment ID corresponding to a Policy Definition Id could be empty.
               
            </TeachingBubble>
        )}

        {teachingBubbleVisiblePolicyProperties && (
            <TeachingBubble
                target="#customPolicyProperties-info"
                headline="Edit Policy Properties"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={commentsNext}
                // secondaryButtonProps={commentsPrevious}
                // footerContent="19 of 19"
                onDismiss={toggleTeachingBubbleVisiblePolicyProperties}
                closeButtonAriaLabel='Close Info'
            >
                Edit the properties to used for evaluation of Policy controls.
               
            </TeachingBubble>
        )}

        {teachingBubbleVisibleSettings && (
            <TeachingBubble
                target="#optional-control-settings-info"
                headline="Enter Control Settings"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={settingsNext}
                // secondaryButtonProps={settingsPrevious}
                // footerContent="18 of 19"
                onDismiss={toggleTeachingBubbleVisibleSettings}
                closeButtonAriaLabel='Close Info'
            >
                Settings specific to the control to be provided for the scan.
               
            </TeachingBubble>
        )}

        {teachingBubbleVisibleComments && (
            <TeachingBubble
                target="#comments-info"
                headline="Enter Comments"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                // primaryButtonProps={commentsNext}
                // secondaryButtonProps={commentsPrevious}
                // footerContent="19 of 19"
                onDismiss={toggleTeachingBubbleVisibleComments}
                closeButtonAriaLabel='Close Info'
            >
                Comments by the user that show up in the changelog for the feature.
               
            </TeachingBubble>
        )}

      </Stack>
    </>
  );
};
